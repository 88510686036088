import React from "react";

// Components
import { DatePicker } from "@material-ui/pickers";

//
export interface IYearPickerDialogProps {
  open: boolean;
  onClose?: () => void;
  minYear?: number;
  maxYear?: number;
  value: number;
  onChange: (value: number) => void;
}
export const YearPickerDialog: React.FunctionComponent<IYearPickerDialogProps> = props => {
  return (
    <DatePicker
      open={props.open}
      value={new Date(`${props.value}-01-01`)}
      onChange={date => {
        if (date) {
          props.onChange((date as Date).getFullYear());
        }
      }}
      onClose={props.onClose}
      views={["year"]}
      minDate={props.minYear ? new Date(`${props.minYear}-01-01`) : null}
      maxDate={props.maxYear ? new Date(`${props.maxYear}-01-01`) : null}
      variant="dialog"
      TextFieldComponent={() => null}
    />
  );
};
