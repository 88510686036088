import React from "react";

// Schema
import { PositionSchema, IPositionSchemaPayload } from "schema";

// Components
import Dialog from "@material-ui/core/Dialog";

// Constants
import { ItemType } from "../ProgramMemberTable";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";

//
export interface IMultiFunctionSelectorModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (memberContractId: string, itemType: ItemType) => void;
}
export const MultiFunctionSelectorModal: React.FunctionComponent<IMultiFunctionSelectorModalProps> =
  React.memo((props) => {
    const handleSelect = (item: IPositionSchemaPayload) => {
      props.onClose();
      props.onSelect(item.name, ItemType.MultiFunction);
    };

    return (
      <Dialog
        maxWidth="md"
        fullWidth
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{ style: { height: "100%" } }}
      >
        <SqlrlWindowDataTable
          schema={PositionSchema}
          fields={["name", "created"]}
          // filter={[
          //   {
          //     fieldName: "startTime",
          //     value: `${props.displayYear}-01-01..${
          //       parseInt(props.displayYear as any) + 1
          //     }-01-01`,
          //   },
          // ]}
          // groupBy={["programId"]}
          title="Vælg produktion"
          // widths={{
          //   title: 280,
          //   productionNumber: 180,
          //   genre: 200,
          //   subtitle: 180,
          //   programId: 300,
          // }}
          onRowClick={handleSelect}
          // showDragHandle
        />
      </Dialog>
    );
  });
