import React from "react";

// Schema
import { MemberSchema, IMemberSchemaPayload } from "schema";

// Containers
import { AuthContainer } from "containers/AuthContainer";
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Components
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
//
export interface IMemberInfoProps {}
export const MemberInfo: React.FunctionComponent<IMemberInfoProps> = (
  props
) => {
  const authContainer = AuthContainer.useContainer();
  const dialogContainer = DialogContainer.useContainer();

  const [member, setMember] = React.useState<Pick<
    IMemberSchemaPayload,
    | "memberId"
    | "name"
    | "address"
    | "cpr"
    | "postCode"
    | "city"
    | "phoneNumber"
    | "mobile"
    | "email"
    | "alias1"
    | "alias2"
    | "alias3"
    | "alias4"
    | "alias5"
    | "alias6"
    | "alias7"
    | "alias8"
    | "alias9"
    | "alias10"
  > | null>();

  const fetchMemeber = () => {
    if (authContainer.context && authContainer.context.memberId) {
      MemberSchema.select({
        fields: [
          "memberId",
          "name",
          "address",
          "cpr",
          "postCode",
          "city",
          "phoneNumber",
          "mobile",
          "email",
          "alias1",
          "alias2",
          "alias3",
          "alias4",
          "alias5",
          "alias6",
          "alias7",
          "alias8",
          "alias9",
          "alias10",
        ],
        filter: [
          {
            fieldName: "memberId",
            value: authContainer.context.memberId,
          },
        ],
      })
        .then((res) => {
          setMember(res.data[0]);
        })
        .catch(dialogContainer.error.handleError);
    }
  };

  React.useEffect(() => {
    fetchMemeber();
  }, []);

  const alias = member
    ? [
        member.alias1,
        member.alias2,
        member.alias3,
        member.alias4,
        member.alias5,
        member.alias6,
        member.alias7,
        member.alias8,
        member.alias9,
        member.alias10,
      ]
        .filter((item) => !!item)
        .join(", ")
    : null;

  return (
    <Paper
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
      }}
    >
      <ListSubheader style={{ fontSize: "16px" }}>
        Dine oplysninger - {member ? member.memberId : "Indlæser"}
      </ListSubheader>
      <Divider></Divider>
      <List disablePadding style={{ overflowY: "scroll", height: "100%" }}>
        {member && (
          <Grid container>
            <Grid item xs={6}>
              <ListItem dense>
                <ListItemText
                  primary="Navn"
                  secondary={member.name}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>
              <ListItem dense>
                <ListItemText
                  primary="Adresse"
                  secondary={`${member.address}, ${member.postCode} ${member.city}`}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>
              <ListItem dense>
                <ListItemText
                  primary="Mobil"
                  secondary={member.mobile}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={6}>
              <ListItem dense>
                <ListItemText
                  primary="Email"
                  secondary={member.email}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>
              <ListItem dense>
                <ListItemText
                  primary="CPR"
                  secondary={member.cpr}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>
              <ListItem dense>
                <ListItemText
                  primary="Phone"
                  secondary={member.phoneNumber}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>
            </Grid>
            <Grid item xs={12}>
              <ListItem dense>
                <ListItemText
                  primary="Alias"
                  secondary={alias || "Ingen alias"}
                  secondaryTypographyProps={{ noWrap: true }}
                />
              </ListItem>
            </Grid>
          </Grid>
        )}
      </List>
    </Paper>
  );
};
