import React from "react";
import {
  FilePond,
  FilePondProps,
  FilePondLabelProps,
  registerPlugin
} from "react-filepond";

// Styles
import "filepond/dist/filepond.min.css";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Backend
import { backend, baseUrl } from "backend";

// Register the plugins
const FilePondPluginFileValidationType = require("filepond-plugin-file-validate-type");
registerPlugin(FilePondPluginFileValidationType);

//
export interface IFileUploaderProps
  extends FilePondLabelProps,
    Pick<
      FilePondProps,
      | "acceptedFileTypes"
      | "allowDrop"
      | "allowBrowse"
      | "allowPaste"
      | "allowMultiple"
      | "allowRevert"
      | "maxFiles"
      | "maxParallelUploads"
      | "dropOnPage"
      | "dropOnElement"
      | "dropValidation"
      | "instantUpload"
      | "onwarning"
      | "onerror"
      | "onaddfilestart"
      | "onaddfile"
      | "onaddfileprogress"
      | "onremovefile"
      | "onupdatefiles"
      // | "beforeAddFile"
      // | "beforeDropFile"
      | "beforeRemoveFile"
    > {
  files: any[];
  params?: { [k: string]: string | number | null | boolean | undefined };
}
export const FileUploader = (props: IFileUploaderProps) => {
  const authContainer = AuthContainer.useContainer();

  const filepondRef = React.useRef<FilePond | null>(null);
  return (
    <div>
      <FilePond
        ref={ref => (filepondRef.current = ref)}
        files={props.files}
        allowMultiple={true}
        maxFiles={3}
        server={{
          process: {
            url: backend.getUri({
              url: baseUrl + "member.contract.upload",
              params: props.params || {}
            }),
            headers: authContainer.token
              ? {
                  Authorization: authContainer.token
                }
              : {}
          }
        }}
        {...props}
      />
    </div>
  );
};
