import React from "react";
import { RouteComponentProps } from "@reach/router";
import { AuthContainer } from "containers/AuthContainer";

// Components
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Container } from "@discoverr/ui/components/Layout/Container";

// Schema
import {
  MemberProgramYearSchema,
  IMemberProgramYearSchemaPayload,
} from "schema";

//
export interface IPaymentProps extends RouteComponentProps {}
export const Payment: React.FunctionComponent<IPaymentProps> = (props) => {
  const authContainer = AuthContainer.useContainer();
  return (
    <Container maxWidth="xl" fullHeight style={{ height: "100%" }}>
      <Paper
        style={{ height: "100%", width: "100%", overflow: "hidden" }}
        elevation={6}
      >
        <iframe
          src={
            "https://www.dj-retsys.dk/Broadcasts/MemberPayments?User=" +
            authContainer.context?.memberGuid
          }
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
        />
      </Paper>
    </Container>
  );
};
