import React from "react";
import AnimateHeight from "react-animate-height";

// Backend
import { baseUrl } from "backend";

// Schema
import {
  MemberContractSchema,
  IMemberContractSchemaPayload,
  MemberContractLinkSchema,
  IMemberContractLinkSchemaPayload,
} from "schema";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";
import { AuthContainer } from "containers/AuthContainer";

// Utilities
import { formatDate } from "@discoverr/ui/utilities/format";

// Components
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";

import { MagicFormik, FormikTextField } from "@discoverr/ui/components/Formik";

import { FileUploader } from "../FileUploader";

// Types
type IListItem = Pick<
  IMemberContractSchemaPayload,
  "rowId" | "memberContractId" | "title" | "comment"
>;
type IListItemFiles = Pick<
  IMemberContractLinkSchemaPayload,
  "memberContractLinkId" | "timestamp" | "filename" | "link"
>;

//
export interface IContractUploadModalProps {
  open: boolean;
  contractId?: string | null;
  onClose: () => void;
  onCreated: (memberContractId: string) => void;
  onExited: () => void;
}
export const ContractUploadModal: React.FunctionComponent<IContractUploadModalProps> = (
  props
) => {
  const dialogContainer = DialogContainer.useContainer();
  const authContainer = AuthContainer.useContainer();

  const [showUploader, setShowUploader] = React.useState(false);
  const [files, setFiles] = React.useState<any[]>([]);

  const [contract, setContract] = React.useState<IListItem | null>(null);
  const [contractFiles, setContractFiles] = React.useState<
    IListItemFiles[] | null
  >(null);

  React.useEffect(() => {
    if (props.open && props.contractId) {
      fetchContract();
    }
  }, [props.open, props.contractId]);

  const fetchContract = () => {
    if (props.contractId && authContainer.context?.memberId) {
      MemberContractSchema.select({
        fields: ["rowId", "memberContractId", "title", "comment"],
        filter: [
          { fieldName: "memberId", value: authContainer.context?.memberId },
          { fieldName: "memberContractId", value: props.contractId },
        ],
      }).then((res) => {
        if (res.data[0]) {
          setContract(res.data[0]);
        }
      });

      MemberContractLinkSchema.select({
        fields: ["memberContractLinkId", "timestamp", "filename", "link"],
        filter: [
          { fieldName: "memberId", value: authContainer.context?.memberId },
          { fieldName: "memberContractId", value: props.contractId },
        ],
      }).then((res) => {
        if (res.data) {
          setContractFiles(res.data);
        }
      });
    }
  };

  const handleUploadOpen = () => {
    setShowUploader(true);
  };

  const handleUploadClose = () => {
    setFiles([]);
    setShowUploader(false);
    fetchContract();
  };

  const handleUploadExited = () => {
    setFiles([]);
    setShowUploader(false);
  };

  const handleEnter = () => {};

  const handleExited = () => {
    setContract(null);
    setContractFiles(null);
    props.onExited();
  };

  const handleDelete = () => {
    dialogContainer.confirm.create({
      title: "Er du sikker?",
      message: "Du er ved at slette denne kontrakt.",
      confirmText: "Slet",
      closeText: "Annullere",
      onConfirm: () => {
        if (authContainer.context?.memberId && props.contractId) {
          MemberContractSchema.delete({
            fields: ["rowId", "memberContractId", "title", "comment"],
            filter: [
              { fieldName: "memberId", value: authContainer.context?.memberId },
              { fieldName: "memberContractId", value: props.contractId },
            ],
          })
            .then(() => {
              props.onClose();
            })
            .catch(dialogContainer.error.handleError);
        }
      },
    });
  };

  const handleSubmit = ({
    title,
    comment,
  }: {
    title: string;
    comment: string;
  }) => {
    if (authContainer.context?.memberId) {
      if (props.contractId) {
        MemberContractSchema.update(
          {
            memberId: authContainer.context?.memberId,
            title,
            comment,
          },
          {
            fields: ["memberContractId"],
            filter: [
              { fieldName: "memberContractId", value: props.contractId },
            ],
          }
        )
          .then((res) => {
            props.onClose();
          })
          .catch(dialogContainer.error.handleError);
      } else {
        MemberContractSchema.insert(
          {
            memberId: authContainer.context?.memberId,
            title,
            comment,
          },
          { fields: ["memberContractId"] }
        )
          .then((res) => {
            props.onCreated(res.data[0].memberContractId);
          })
          .catch(dialogContainer.error.handleError);
      }
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onEnter={handleEnter}
      onClose={props.onClose}
      onExited={handleExited}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <MagicFormik
        initialValues={
          contract
            ? { title: contract.title, comment: contract.comment }
            : { title: "", comment: "" }
        }
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ Field, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="customized-dialog-title">
              {contract ? contract.title : "Upload kontrakt"}
            </DialogTitle>
            <DialogContent dividers>
              <Field name="title">
                {({ field, meta }) => (
                  <FormikTextField
                    field={field}
                    meta={meta}
                    label="Title"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 50 }}
                  ></FormikTextField>
                )}
              </Field>
              <Field name="comment">
                {({ field, meta }) => (
                  <FormikTextField
                    field={field}
                    meta={meta}
                    label="Kommentar"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 500 }}
                  ></FormikTextField>
                )}
              </Field>

              <AnimateHeight
                style={{
                  marginTop: 16,
                  marginBottom: 8,
                  borderRadius: 4,
                  border: "1px solid rgba(0,0,0,0.20)",
                  opacity: props.contractId ? 1 : 0,
                }}
                height={
                  (contractFiles ? contractFiles.length : 0) * 72 +
                  (props.contractId ? 72 : 0)
                }
              >
                <List disablePadding>
                  {contractFiles
                    ? contractFiles.map((item) => (
                        <ListItem
                          key={item.memberContractLinkId}
                          button
                          onClick={() => {
                            window.open(
                              baseUrl +
                                "member.contract.download?memberContractLinkId=" +
                                item.memberContractLinkId,
                              "_blank"
                            );
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <Icon>home</Icon>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.filename}
                            secondary={formatDate(item.timestamp)}
                          />
                          {/* <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete">
                              <Icon>delete</Icon>
                            </IconButton>
                          </ListItemSecondaryAction> */}
                        </ListItem>
                      ))
                    : null}

                  <ListItem button onClick={handleUploadOpen}>
                    <ListItemAvatar>
                      <Avatar>
                        <Icon>cloud_upload</Icon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Upload fil"
                      secondary="Tilføj nye filer kontrakter"
                    />
                  </ListItem>
                </List>
              </AnimateHeight>
            </DialogContent>
            <DialogActions>
              {/* {props.contractId ? (
                <Button startIcon={<Icon>delete</Icon>} onClick={handleDelete}>
                  Slet
                </Button>
              ) : null} */}
              <div style={{ flexGrow: 1 }}></div>
              <Button onClick={props.onClose}>Annuller</Button>
              <Button type="submit" color="primary">
                {props.contractId ? "Gem" : "Opret"}
              </Button>
            </DialogActions>
          </form>
        )}
      </MagicFormik>
      <Dialog
        open={showUploader}
        onClose={handleUploadClose}
        onExited={handleUploadExited}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title">
          Upload kontrakter
        </DialogTitle>
        <DialogContent dividers>
          <AnimateHeight style={{}} height={files.length * 60 + 76}>
            <FileUploader
              files={files}
              allowRevert={false}
              onupdatefiles={(fileItems) => {
                setFiles(fileItems.map((fileItem) => fileItem.file));
              }}
              acceptedFileTypes={[
                "application/pdf",
                "application/msword",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ]}
              params={{ memberContractId: props.contractId }}
            ></FileUploader>
          </AnimateHeight>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleUploadClose}>Luk</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
