import { createAuthContainer } from "@discoverr/ui/containers/AuthContainer";

//
export const AuthContainer = createAuthContainer<
  "MEMBER",
  {
    memberId: number;
    memberGuid: number;
  }
>();
