import React from "react";
import { Router, Link, Redirect, RouteComponentProps } from "@reach/router";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Components
import Icon from "@material-ui/core/Icon";
import { AuthGuard } from "@discoverr/ui/components/View";
import { LargeTopBar } from "@discoverr/ui/components/AppBar";
import { HeaderFlexLayout, RouterFlexLayout } from "components/Layout";

import { TermsOfAgreementModal } from "./components/TermsOfAgreementModal";

// Views
import { Approval } from "./views/Approval";
import { Payment } from "./views/Payment";
import { SpaceSettingSchema } from "schema";
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Assets
const logoPNG = require("assets/dj-logo-white.png");

//
export interface IAppProps extends RouteComponentProps {}
export const App: React.FunctionComponent<IAppProps> = (props) => {
  const authContainer = AuthContainer.useContainer();
  const dialogContainer = DialogContainer.useContainer();
  const routeValue = (props as { "*": string })["*"];

  const handleLogout = () => {
    authContainer.logOut();
    props.navigate!("/login", { replace: true });
  };

  const year = new Date().getFullYear() - 1;

  const tabItems = [
    {
      routeName: "approval/" + year,
      label: "Godkendelse",
    },
    {
      routeName: "payment",
      label: "Udbetaling",
    },
  ];

  const handleHelp = () => {
    SpaceSettingSchema.select({
      filter: [{ fieldName: "code", value: "SITE_MEMBER_FAQ" }],
    })
      .then((res) => {
        if (res.data[0] && res.data[0].value) {
          window.open(res.data[0].value, "_blank");
        }
      })
      .catch(dialogContainer.error.handleError);
  };
  const handleContact = () => {
    SpaceSettingSchema.select({
      filter: [{ fieldName: "code", value: "SITE_MEMBER_CONTACT" }],
    })
      .then((res) => {
        if (res.data[0] && res.data[0].value) {
          dialogContainer.alert.create({
            title: "Kontakt oplysninger",
            message: res.data[0].value,
            closeText: "Luk",
          });
        }
      })
      .catch(dialogContainer.error.handleError);
  };

  return (
    <AuthGuard AuthContainer={AuthContainer}>
      <HeaderFlexLayout
        header={
          <LargeTopBar
            development={false}
            logo={
              <Link to="/">
                <img src={logoPNG} style={{ width: "auto", height: 30 }} />
              </Link>
            }
            tabItems={tabItems}
            currentRouteName={routeValue}
            menuIcon={<Icon>help_outline</Icon>}
            menuItems={[
              {
                label: "Hjælp",
                onClick: handleHelp,
              },
              {
                label: "Kontakt",
                onClick: handleContact,
              },
            ]}
            onLogOut={handleLogout}
          />
        }
      >
        <Router component={RouterFlexLayout}>
          <Redirect from="/" to={"approval/" + year} noThrow />
          <Approval path="/approval/:year" />
          <Payment path="/payment" />
        </Router>
      </HeaderFlexLayout>
      <TermsOfAgreementModal />
    </AuthGuard>
  );
};
