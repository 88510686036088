import React from "react";

// Schema
import { SpaceSettingSchema } from "schema";

// Components
import Paper from "@material-ui/core/Paper";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

//
export interface ISiteInfoProps {}
export const SiteInfo: React.FunctionComponent<ISiteInfoProps> = () => {
  const [siteMemberInfo, setSiteMemberInfo] = React.useState<string | null>(
    "Henter oplysninger..."
  );

  React.useEffect(() => {
    SpaceSettingSchema.select({
      fields: ["value"],
      filter: [{ fieldName: "code", value: "SITE_MEMBER_INFO" }],
    }).then((res) => {
      if (res.data && res.data[0]) {
        setSiteMemberInfo(res.data[0].value);
      }
    });
  }, []);

  return (
    <Paper
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
      }}
    >
      <ListSubheader style={{ fontSize: "16px" }}>Information</ListSubheader>
      <Divider />
      <Box p={2} height="100%" style={{ overflowY: "scroll" }}>
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: siteMemberInfo
              ? siteMemberInfo.replace(/(?:\r\n|\r|\n)/g, "<br>")
              : "",
          }}
        ></Typography>
      </Box>
    </Paper>
  );
};
