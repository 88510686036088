import React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { useNavigate, useParams } from "@reach/router";
import AutoSizer from "react-virtualized-auto-sizer";
import produce from "immer";

// Containers
import { AuthContainer } from "containers/AuthContainer";
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";

// Schema
import {
  BroadcastViewSchema,
  ProgramSchema,
  MemberProgramSchema,
  MemberProgramViewSchema,
  IMemberProgramViewSchemaPayload,
  PersonSchema,
  MemberProgramYearSchema,
  IMemberProgramYearSchemaPayload,
} from "schema";

// Utilities
import { ScrollWidth } from "@discoverr/ui/constants/browser";

// Components
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Fade from "@material-ui/core/Fade";

import {
  SqlrlDetailsModal,
  useSqlrlDetailsModalLink,
} from "@discoverr/ui/components/Modal/SqlrlDetailsModal";
import { SqlrlDetailView } from "@discoverr/ui/components/Detail/SqlrlDetailView";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";

import { ContractSelectorModal } from "../ContractSelectorModal";
import { FunctionSelectorModal } from "../FunctionSelectorModal";
import { MultiFunctionSelectorModal } from "../MultiFunctionSelectorModal";
import { RecuritmentSelectorModal } from "../RecuritmentSelectorModal";
import { ProgramReferalModal } from "../ProgramReferalModal";
import { BroadcastReferalModal } from "../BroadcastReferalModal";
import { YearPickerDialog } from "../YearPickerDialog";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import { BroadcastAvuListTab } from "components/BroadcastAvuListTab";
import { backend } from "backend";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
  },

  tableContainer: { flexGrow: 1, flexDirection: "column", display: "flex" },

  row: {
    height: 100,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    outline: "none",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.03)",
    },
  },
  rowTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space",
    cursor: "pointer",
  },
  rowBottom: { paddingLeft: 64, paddingRight: 12 },
  cell: {
    padding: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

// Constants
enum ActionTypes {
  ContractAdd,
  FunctionAdd,
  MultiFunctionAdd,
  RecruitmentAdd,
  WorkdaysAdd,
  NotMeAdd,
  Approve,
}

const shouldShowWorkdays = (jobFunction: string) => {
  const allowed = [
    "Danske tekster",
    "Delvis tekster/TTV tekster",
    "Tekster",
    "TTV tekster",
    "Undertekster",
    "Tekstet af",
    "Tekstning",
  ];
  return !allowed.includes(jobFunction);
};

export enum ItemType {
  Contract,
  Function,
  MultiFunction,
  Recruitment,
  WorkdaysAdd,
}

interface ITableToolBarProps {
  count?: number | null;
  selectedCount?: number;
  selectedYear?: number;
  closed: boolean;
  handleActionSelected: (actionType: ActionTypes) => void;
  handleAddProduction: () => void;
  handleAddBroadcast: () => void;
  handleYearPickerSelect: () => void;
}
const TableToolBar = React.memo(
  ({
    count,
    selectedCount,
    selectedYear,
    closed,
    handleActionSelected,
    handleAddProduction,
    handleAddBroadcast,
    handleYearPickerSelect,
  }: ITableToolBarProps) => {
    return (
      <Toolbar style={{ paddingLeft: 16, paddingRight: 12 }}>
        <Typography variant="h6">
          {selectedCount
            ? `${selectedCount} valgt`
            : `${count || "..."} Produktioner`}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <Fade in={!!selectedCount}>
          <div>
            <Button
              variant="outlined"
              startIcon={<Icon>add</Icon>}
              onClick={() => handleActionSelected(ActionTypes.MultiFunctionAdd)}
              style={{ marginRight: 8 }}
            >
              Funktion
            </Button>
            <Button
              variant="outlined"
              startIcon={<Icon>add</Icon>}
              onClick={() => handleActionSelected(ActionTypes.WorkdaysAdd)}
              style={{ marginRight: 8 }}
            >
              arbejdsdage
            </Button>
            <Button
              variant="outlined"
              startIcon={<Icon>add</Icon>}
              onClick={() => handleActionSelected(ActionTypes.RecruitmentAdd)}
              style={{ marginRight: 8 }}
            >
              Ansættelsesform
            </Button>
            <Button
              variant="outlined"
              startIcon={<Icon>add</Icon>}
              onClick={() => handleActionSelected(ActionTypes.ContractAdd)}
              style={{ marginRight: 8 }}
            >
              Kontrakt
            </Button>
            <Button
              variant="outlined"
              startIcon={<Icon>check</Icon>}
              onClick={() => handleActionSelected(ActionTypes.Approve)}
              style={{ marginRight: 8 }}
            >
              Godkend
            </Button>
          </div>
        </Fade>
        <Button
          variant="outlined"
          startIcon={<Icon>calendar_today</Icon>}
          onClick={handleYearPickerSelect}
          style={{ marginRight: 8 }}
        >
          År: {selectedYear || "Indlæser"}
        </Button>
        <Button
          variant="outlined"
          disabled={closed}
          startIcon={<Icon>add</Icon>}
          onClick={handleAddProduction}
          style={{ marginRight: 8 }}
        >
          produktion
        </Button>

        <Button
          variant="outlined"
          disabled={closed}
          startIcon={<Icon>add</Icon>}
          onClick={handleAddBroadcast}
        >
          udsendelse
        </Button>
      </Toolbar>
    );
  }
);
interface IHeadProps {
  allSelected: boolean;
  noneSelected: boolean;
  onAllToggle: (isAllSelected: boolean) => void;
}
const Head: React.FC<IHeadProps> = React.memo((props) => {
  const handleCheckboxClick = (e: React.MouseEvent<any>) => e.stopPropagation();

  const handleAllSelectChange = () => {
    props.onAllToggle(props.allSelected);
  };

  return (
    <Table component="div">
      <TableHead component="div">
        <TableRow
          id="TablerHeader"
          component="div"
          tabIndex={-1}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space",
            paddingRight: ScrollWidth,
          }}
        >
          <TableCell
            component="div"
            key="checkbox"
            padding="checkbox"
            style={{
              maxWidth: 56,
              minWidth: 56,
              width: 56,
              padding: 4,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              color="primary"
              checked={props.allSelected}
              indeterminate={!props.allSelected && !props.noneSelected}
              onClick={handleCheckboxClick}
              onChange={handleAllSelectChange}
            />
          </TableCell>
          <TableCell
            component="div"
            key="status"
            style={{ maxWidth: 120, minWidth: 120, width: 120 }}
          >
            Status
          </TableCell>
          <TableCell
            component="div"
            key="title"
            style={{ flexGrow: 1, minWidth: 200 }}
          >
            Titel
          </TableCell>
          <TableCell
            component="div"
            key="season"
            style={{ maxWidth: 100, minWidth: 100, width: 100 }}
            align={"right"}
          >
            Sæson
          </TableCell>
          <TableCell
            component="div"
            key="episode"
            style={{ maxWidth: 100, minWidth: 100, width: 100 }}
            align={"right"}
          >
            Episode
          </TableCell>
          <TableCell
            component="div"
            key="productionYear"
            style={{ maxWidth: 120, minWidth: 120, width: 120 }}
            align={"right"}
          >
            Produktionsår
          </TableCell>

          <TableCell
            component="div"
            key="arbejdsdage"
            style={{ maxWidth: 120, minWidth: 120, width: 120 }}
            align={"right"}
          >
            Arbejdsdage
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
});

interface IRowProps extends IProgramMemberTableItem {
  index: number;
  className?: string;
  rowTopClassName?: string;
  rowBottomClassName?: string;
  cellClassName?: string;
  onClick: (programId: string) => void;
  onCheck?: (index: number) => void;
  onActionClick?: (
    programMemberId: string,
    action: ActionTypes,
    meta?: any
  ) => void;
}
const Row = React.memo((props: IRowProps) => {
  const isReady = props.status === "KLAR";
  return (
    <div
      className={props.className}
      tabIndex={-1}
      onClick={(e) => {
        e.stopPropagation();
        props.programId && props.onClick(props.programId);
      }}
    >
      <div className={props.rowTopClassName}>
        <div
          className={props.cellClassName}
          key="checkbox"
          style={{ maxWidth: 56, minWidth: 56, width: 56, padding: 4 }}
        >
          <Checkbox
            color="primary"
            checked={props.selected}
            disabled={!isReady}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              props.onCheck && props.onCheck(props.index);
            }}
          />
        </div>
        <div
          className={props.cellClassName}
          key="status"
          style={{ maxWidth: 120, minWidth: 120, width: 120 }}
        >
          {props.status}
        </div>
        <div
          className={props.cellClassName}
          key="title"
          style={{ flexGrow: 1, minWidth: 200 }}
        >
          {props.programTitle}
        </div>
        <div
          className={props.cellClassName}
          key="season"
          style={{
            maxWidth: 100,
            minWidth: 100,
            width: 100,
            textAlign: "right",
          }}
        >
          {props.programSeasonNumber}
        </div>
        <div
          className={props.cellClassName}
          key="episode"
          style={{
            maxWidth: 100,
            minWidth: 100,
            width: 100,
            textAlign: "right",
          }}
        >
          {props.programEpisodeNumber}
        </div>
        <div
          className={props.cellClassName}
          key="productionYear"
          style={{
            maxWidth: 120,
            minWidth: 120,
            width: 120,
            textAlign: "right",
          }}
        >
          {props.programProductionYear}
        </div>
        <div
          className={props.cellClassName}
          key="workdays"
          style={{
            maxWidth: 120,
            minWidth: 120,
            width: 120,
            textAlign: "right",
          }}
        >
          {props.workdays}
        </div>
      </div>
      <div className={props.rowBottomClassName}>
        <Button
          startIcon={<Icon>{props.jobFunction ? "clear" : "add"}</Icon>}
          disabled={!isReady}
          onClick={(e) => {
            e.stopPropagation();
            props.onActionClick &&
              props.onActionClick(
                props.programMemberId,
                ActionTypes.FunctionAdd
              );
          }}
        >
          {props.jobFunction || "Funktion"}
        </Button>
        <Button
          startIcon={<Icon>{props.workdays ? "clear" : "add"}</Icon>}
          disabled={!isReady}
          onClick={(e) => {
            e.stopPropagation();
            props.onActionClick &&
              props.onActionClick(
                props.programMemberId,
                ActionTypes.WorkdaysAdd,
                {
                  jobFunction: props.jobFunction,
                  workdays: props.workdays,
                }
              );
          }}
        >
          {shouldShowWorkdays(props.jobFunction)
            ? props.workdays
              ? props.workdays + " Dage"
              : "Indtast arbejdsdage"
            : props.workdays
            ? props.workdays + " Procent"
            : "Indtast procent"}
        </Button>
        <Button
          startIcon={<Icon>{props.recruitmentForm ? "clear" : "add"}</Icon>}
          disabled={!isReady}
          onClick={(e) => {
            e.stopPropagation();
            props.onActionClick &&
              props.onActionClick(
                props.programMemberId,
                ActionTypes.RecruitmentAdd
              );
          }}
        >
          {props.recruitmentForm || "Ansættelsesform"}
        </Button>
        <Button
          startIcon={<Icon>{props.contractTitle ? "clear" : "add"}</Icon>}
          disabled={!isReady}
          onClick={(e) => {
            e.stopPropagation();
            props.onActionClick &&
              props.onActionClick(
                props.programMemberId,
                ActionTypes.ContractAdd
              );
          }}
        >
          {props.contractTitle || "kontrakt"}
        </Button>
        <Button
          startIcon={<Icon>block</Icon>}
          disabled={!isReady}
          onClick={(e) => {
            e.stopPropagation();
            props.onActionClick &&
              props.onActionClick(props.programMemberId, ActionTypes.NotMeAdd);
          }}
        >
          Ikke Mig
        </Button>
        <Button
          startIcon={<Icon>check</Icon>}
          disabled={
            !(
              !!props.recruitmentForm &&
              !!props.jobFunction &&
              !!props.contractTitle &&
              !!props.workdays &&
              isReady
            )
          }
          onClick={(e) => {
            e.stopPropagation();
            props.onActionClick &&
              props.onActionClick(props.programMemberId, ActionTypes.Approve);
          }}
        >
          Godkend
        </Button>
      </div>
    </div>
  );
});

// Types
export type IProgramMemberTableItem = Pick<
  IMemberProgramViewSchemaPayload,
  | "programId"
  | "programMemberId"
  | "programChannel"
  | "programTitle"
  | "programSeasonNumber"
  | "programEpisodeNumber"
  | "programProductionYear"
  | "contractTitle"
  | "programSubtitle"
  | "jobFunction"
  | "recruitmentForm"
  | "notMe"
  | "memberContractId"
  | "status"
  | "workdays"
> & { selected?: boolean };
export type ISelectedYear = {
  year: number;
  closed: boolean;
};

//
export interface IProgramMemberTableProps {
  yearRange: [number, number];
  getYearClosedStatus: (year: number) => boolean;
}
export const ProgramMemberTable: React.FunctionComponent<IProgramMemberTableProps> =
  React.memo((props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();
    const authContainer = AuthContainer.useContainer();
    const dialogContainer = DialogContainer.useContainer();

    const [link, handles] = useSqlrlDetailsModalLink<typeof ProgramSchema>();

    const [productions, setProductions] = React.useState<
      IProgramMemberTableItem[] | null
    >(null);
    const [showYearPicker, setShowYearPicker] = React.useState<boolean>(false);
    const [selectedYear, setSelectedYear] = React.useState<ISelectedYear>({
      year: params.year,
      closed: props.getYearClosedStatus(params.year),
    });
    const [programReferalOpen, setProgramReferalOpen] = React.useState(false);
    const [broadcastReferalOpen, setBroadcastReferalOpen] =
      React.useState(false);

    const fetchProductions = (selectedYear: ISelectedYear) => {
      return MemberProgramViewSchema.select({
        fields: [
          "programId",
          "programMemberId",
          "programChannel",
          "programTitle",
          "programSeasonNumber",
          "programEpisodeNumber",
          "programProductionYear",
          "contractTitle",
          "programSubtitle",
          "jobFunction",
          "notMe",
          "recruitmentForm",
          "status",
          "workdays",
        ],
        filter: [
          {
            fieldName: "memberId",
            value: authContainer.context?.memberId || null,
          },
          {
            fieldName: "displayYear",
            value: selectedYear.year,
          },
          {
            fieldName: "notMe",
            value: "false",
          },
          {
            fieldName: "status",
            value: selectedYear.closed
              ? "AFVENTER TILBAGEMELDING|AFVIST|GODKENDT|INDSENDT|MANGLER COPYDAN FORBEHOLD|MANGLER KONTRAKT|UDBETALT"
              : "*",
          },
        ],
        sorting: [
          { fieldName: "status", order: "desc" },
          { fieldName: "programTitle", order: "asc" },
          { fieldName: "programSeasonNumber", order: "asc" },
          { fieldName: "programEpisodeNumber", order: "asc" },
        ],
        limit: 99999,
      }).then((res) => {
        setProductions((state) => {
          return (res.data as IProgramMemberTableItem[]).map((item, index) => ({
            ...item,
            selected:
              state && state[index]
                ? state[index].programMemberId === item.programMemberId
                  ? state[index].selected
                  : false
                : false,
          }));
        });
      });
    };

    React.useEffect(() => {
      fetchProductions(selectedYear);
    }, [selectedYear.year]);

    const [selectedProgramMemberId, setSelectedProgramMemberId] =
      React.useState<{ ids: string[] | null; itemType: ItemType }>({
        ids: null,
        itemType: ItemType.Contract,
      });
    const allSelectable = productions?.filter(
      (item) => item.status === "KLAR"
    ).length;
    const selectedCount = productions?.filter((item) => !!item.selected).length;
    const allSelected = productions?.length
      ? allSelectable
        ? allSelectable > 0 && allSelectable === selectedCount
        : false
      : false;
    const noneSelected = productions?.length ? selectedCount === 0 : true;

    const showWorkdaysDialog =
      selectedProgramMemberId.itemType === ItemType.WorkdaysAdd &&
      !!selectedProgramMemberId.ids &&
      selectedProgramMemberId.ids.length > 0;
    React.useEffect(() => {
      if (showWorkdaysDialog) {
        dialogContainer.textField.create({
          title: "Indtast arbejdsdag/procent",
          message:
            "Indtast antal arbejdsdage du har brugt på produktionen. Hvis du har produceret en serie med flere afsnit kan du dele det samlede antal arbejdsdage med antal afsnit. Hvis tekstet, indtast procent du har tekstet af produktionen.",
          label: "Arbejdsdage/Procent (heltal)",
          type: "number",
          initialValue: "",
          onSubmit: (value) => {
            handleActionSelect(value, ItemType.WorkdaysAdd);
            handleClose();
          },
        });
      }
    }, [showWorkdaysDialog]);

    const handleRowClick = React.useCallback(
      (programId: string) => {
        handles.view([{ fieldName: "programId", value: programId }]);
      },
      [handles]
    );

    const handleWorkdayAdd = (
      programMemberId: string,
      jobFunction: string,
      workdays: string
    ) => {
      const isWorkdays = shouldShowWorkdays(jobFunction);
      dialogContainer.textField.create({
        title: isWorkdays ? "Indtast arbejdsdag" : "Indtast procent",
        initialValue: workdays,
        message: isWorkdays
          ? "Indtast antal arbejdsdage du har brugt på produktionen. Hvis du har produceret en serie med flere afsnit kan du dele det samlede antal arbejdsdage med antal afsnit."
          : "Indtast procent du har tekstet af produktionen.",
        label: isWorkdays ? "Arbejdsdage (heltal)" : "Procent (heltal)",
        type: "number",
        onSubmit: (value) => {
          MemberProgramSchema.update(
            {
              workdays: value ? parseFloat(value) : 0,
            },
            {
              filter: [
                {
                  fieldName: "programMemberId",
                  value: programMemberId,
                },
              ],
            }
          )
            .then(() => {
              console.log(selectedYear);
              fetchProductions(selectedYear);
            })
            .catch((error) => {
              dialogContainer.error.handleError(error);
            });
        },
      });
    };

    const handleActionClick = React.useCallback(
      (programMemberId: string, action: ActionTypes, meta?: any) => {
        switch (action) {
          case ActionTypes.RecruitmentAdd:
            setSelectedProgramMemberId({
              ids: [programMemberId],
              itemType: ItemType.Recruitment,
            });
            break;
          case ActionTypes.FunctionAdd:
            setSelectedProgramMemberId({
              ids: [programMemberId],
              itemType: ItemType.Function,
            });
            break;
          case ActionTypes.ContractAdd:
            setSelectedProgramMemberId({
              ids: [programMemberId],
              itemType: ItemType.Contract,
            });
            break;
          case ActionTypes.NotMeAdd:
            handleActionNotMe(programMemberId, true);
            break;
          case ActionTypes.Approve:
            handleActionApprove([programMemberId]);
            break;
          case ActionTypes.WorkdaysAdd:
            handleWorkdayAdd(programMemberId, meta.jobFunction, meta.workdays);
            break;
          default:
            break;
        }
      },
      [selectedYear]
    );

    const handleActionSelected = React.useCallback(
      (action: ActionTypes) => {
        if (action === ActionTypes.Approve) {
          let hasNotReadyItems = false;
          const programMemberIds = productions
            ?.filter((item) => {
              if (item.selected) {
                var isReady =
                  !!item.recruitmentForm &&
                  !!item.jobFunction &&
                  !!item.contractTitle;

                if (!isReady) {
                  hasNotReadyItems = true;
                }

                return isReady;
              }
              return false;
            })
            .map((item) => item.programMemberId);
          if (programMemberIds) {
            handleActionApprove(programMemberIds, hasNotReadyItems);
            // console.log(programMemberIds, hasNotReadyItems);
          }
        } else {
          const programMemberIds = productions
            ?.filter((item) => item.selected)
            .map((item) => item.programMemberId);

          if (programMemberIds) {
            switch (action) {
              case ActionTypes.RecruitmentAdd:
                setSelectedProgramMemberId({
                  ids: programMemberIds,
                  itemType: ItemType.Recruitment,
                });
                break;
              // THIS ISN'T CURRENTLY SUPPORTED
              // case ActionTypes.FunctionAdd:
              //   setSelectedProgramMemberId({
              //     ids: programMemberIds,
              //     itemType: ItemType.Function
              //   });
              //   break;
              case ActionTypes.MultiFunctionAdd:
                setSelectedProgramMemberId({
                  ids: programMemberIds,
                  itemType: ItemType.MultiFunction,
                });
                break;
              case ActionTypes.ContractAdd:
                setSelectedProgramMemberId({
                  ids: programMemberIds,
                  itemType: ItemType.Contract,
                });
                break;
              case ActionTypes.WorkdaysAdd:
                {
                  setSelectedProgramMemberId({
                    ids: programMemberIds,
                    itemType: ItemType.WorkdaysAdd,
                  });
                }
                break;
              // THIS ISN'T CURRENTLT ALLOWED
              // case ActionTypes.NotMeAdd:
              //   handleActionNotMe(programMemberId, true);
              //   break;
              default:
                break;
            }
          }
        }
      },
      [productions]
    );

    const handleActionNotMe = async (
      programMemberId: string,
      value: boolean
    ) => {
      dialogContainer.confirm.create({
        title: "Bekræft fjernelse af produktion",
        message:
          'Du kan ikke søge produktionen frem igen. Hvis produktionen skal ændres eller tilføjes skal du kontakte Rettighedsmidler hos DJ via "rettighedsmidleridj@journalistforbundet.dk".',
        confirmText: "Ikke Mig",
        closeText: "Annuller",
        onConfirm: async () => {
          try {
            await MemberProgramSchema.update(
              { notMe: value },
              {
                filter: [
                  { fieldName: "programMemberId", value: programMemberId },
                ],
              }
            );
            fetchProductions(selectedYear);
          } catch (error) {
            dialogContainer.error.handleError(error as any);
          }
        },
        status: "warning",
      });
    };

    const handleActionApprove = async (
      programMemberIds: string[],
      hasNotReadyItems: boolean = false
    ) => {
      if (hasNotReadyItems) {
        dialogContainer.alert.create({
          status: "error",
          title: "En eller flere linjer kan ikke godkendes",
          message:
            "Tjek venligst valgte linjer for manglende kontrakt, ansættelsesform eller job funktion.",
          closeText: "Luk",
        });
      } else {
        dialogContainer.confirm.create({
          title: "Bekræft godkendelse",
          message:
            'Ved klik på "Godkend" godkendes linjen, og det er ikke muligt at ændre på linjen efter godkendelse.',
          confirmText: "Godkend",
          closeText: "Annuller",
          onConfirm: async () => {
            if (programMemberIds) {
              dialogContainer.loading.start({
                title: "Indlæser...",
                message: "Vent venligst men vi opdater produktionerne",
              });

              backend
                .post("member.bulkUpdate", {
                  type: "APPROVE",
                  status: "INDSENDT",
                  approvedDate: new Date().toISOString(),
                  ids: programMemberIds.join(";"),
                })
                .then(() => {
                  fetchProductions(selectedYear).then(() => {
                    dialogContainer.loading.stop();
                  });
                })
                .catch(dialogContainer.error.handleError);

              // const promises = programMemberIds.map((programMemberId) => {
              //   return MemberProgramSchema.update(
              //     {
              //       status: "INDSENDT",
              //       approvedDate: new Date().toISOString(),
              //     },
              //     {
              //       filter: [
              //         { fieldName: "programMemberId", value: programMemberId },
              //       ],
              //     }
              //   );
              // });

              // Promise.all(promises)
              //   .then(() => {
              //     fetchProductions(selectedYear).then(() => {
              //       dialogContainer.loading.stop();
              //     });
              //   })
              //   .catch(dialogContainer.error.handleError);
            }
          },
        });
      }
    };

    const handleActionSelect = React.useCallback(
      async (value: any, itemType: ItemType) => {
        console.log(itemType, value);
        try {
          console.log(selectedProgramMemberId.ids?.length);
          if (selectedProgramMemberId.ids) {
            dialogContainer.loading.start({
              title: "Indlæser...",
              message: "Vent venligst men vi opdater produktionerne",
            });
            const update = () => {
              if (selectedProgramMemberId.ids) {
                switch (itemType) {
                  case ItemType.Contract: {
                    return backend.post("member.bulkUpdate", {
                      type: "memberContractId",
                      value: value,
                      ids: selectedProgramMemberId.ids.join(";"),
                    });
                    // return MemberProgramSchema.update(
                    //   {
                    //     memberContractId: value,
                    //   },
                    //   {
                    //     filter: [
                    //       {
                    //         fieldName: "programMemberId",
                    //         value: programMemberId,
                    //       },
                    //     ],
                    //   }
                    // );
                  }
                  case ItemType.Recruitment:
                    return backend.post("member.bulkUpdate", {
                      type: "recruitmentForm",
                      value: value,
                      ids: selectedProgramMemberId.ids.join(";"),
                    });

                  case ItemType.WorkdaysAdd:
                    return backend.post("member.bulkUpdate", {
                      type: "workdays",
                      value: value,
                      ids: selectedProgramMemberId.ids.join(";"),
                    });

                  case ItemType.Function:
                  case ItemType.MultiFunction:
                    return backend.post("member.bulkUpdate", {
                      type: "jobFunction",
                      value: value,
                      ids: selectedProgramMemberId.ids.join(";"),
                    });
                }
              }

              return new Promise((resolve, reject) => {
                reject();
              });
            };

            update()
              .then(() => {
                fetchProductions(selectedYear);
                dialogContainer.loading.stop();
              })
              .catch(dialogContainer.error.handleError);
          }
        } catch (error) {}
      },
      [selectedProgramMemberId, dialogContainer, selectedYear]
    );

    const handleClose = React.useCallback(() => {
      setSelectedProgramMemberId((state) => ({ ...state, ids: null }));
    }, []);

    const handleCheckboxChange = React.useCallback((index: number) => {
      setProductions(
        produce((draft) => {
          if (draft && draft[index] && draft[index].status === "KLAR") {
            draft[index].selected = !draft[index].selected || false;
          }
        })
      );
    }, []);

    const handleAllToggle = React.useCallback((isAllSelected: boolean) => {
      setProductions(
        produce((draft) => {
          if (draft) {
            for (let index = 0; index < draft.length; index++) {
              if (draft[index] && draft[index].status === "KLAR") {
                draft[index].selected = !isAllSelected;
              }
            }
          }
        })
      );
    }, []);

    const handleSelectProgram = () => {
      setProgramReferalOpen(true);
    };

    const handleProgramReferealClose = () => {
      setProgramReferalOpen(false);
    };

    const handleSelectBroadcast = () => {
      setBroadcastReferalOpen(true);
    };

    const handleBroadcastReferealClose = () => {
      setBroadcastReferalOpen(false);
    };

    const handleProgramSelect = (item: {
      title: string | null;
      programId: string | null;
    }) => {
      dialogContainer.confirm.create({
        title: "Bekræft tilføjelse af produktion",
        message:
          'Ved godkendelse bliver "' + item.title + '" tilføjet til din liste.',
        onConfirm: async () => {
          try {
            if (authContainer.context?.memberId && item.programId) {
              // programReferalOpen && handleProgramReferealClose();
              // broadcastReferalOpen && handleBroadcastReferealClose();
              await MemberProgramSchema.insert({
                programId: item.programId,
                memberId: authContainer.context?.memberId,
                displayYear: selectedYear.year,
                manual: true,
              });

              fetchProductions(selectedYear);
            } else {
              throw "Missing programId";
            }
          } catch (error) {
            dialogContainer.alert.create({
              title: "Produktion findes allerede",
              message: "Denne produktion findes allerede i din liste.",
              status: "error",
              closeText: "Luk",
            });
          }
        },
      });
    };

    const handleYearPickerSelect = () => {
      setShowYearPicker(true);
    };

    const handleCloseYearPicker = () => {
      setShowYearPicker(false);
    };

    const renderRow = React.useCallback(
      (props: ListChildComponentProps) => {
        const row = props.data[props.index] as IProgramMemberTableItem;
        return (
          <div style={props.style}>
            <Row
              {...row}
              className={classes.row}
              rowTopClassName={classes.rowTop}
              rowBottomClassName={classes.rowBottom}
              index={props.index}
              cellClassName={classes.cell}
              onClick={handleRowClick}
              onCheck={handleCheckboxChange}
              onActionClick={handleActionClick}
            />
          </div>
        );
      },
      [handleCheckboxChange, handleActionClick, handleRowClick]
    );

    React.useEffect(() => {
      console.log("handles changed");
    }, [handles]);

    const headerGridRef = React.useRef<HTMLDivElement>(null);

    const outerElementType = React.useCallback(
      React.forwardRef<HTMLDivElement, any>((props, ref) => (
        <div
          {...props}
          ref={ref}
          onScroll={(event) => {
            if (headerGridRef.current) {
              headerGridRef.current.style.transform = `translateX(-${event.currentTarget.scrollLeft}px)`;
            }
            props.onScroll(event);
          }}
        />
      )),
      []
    );

    const handleYearChange = (year: number) => {
      setSelectedYear({ year, closed: props.getYearClosedStatus(year) });
      navigate(`/approval/${year}`, { replace: true });
    };

    return (
      <Paper className={classes.root}>
        <TableToolBar
          count={productions ? productions.length : null}
          selectedCount={selectedCount}
          closed={selectedYear.closed}
          selectedYear={selectedYear.year}
          handleActionSelected={handleActionSelected}
          handleAddProduction={handleSelectProgram}
          handleAddBroadcast={handleSelectBroadcast}
          handleYearPickerSelect={handleYearPickerSelect}
        />
        <div className={classes.tableContainer}>
          <div>
            <div ref={headerGridRef}>
              <Head
                allSelected={allSelected}
                noneSelected={noneSelected}
                onAllToggle={handleAllToggle}
              />
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  style={{ overflowY: "scroll" }}
                  itemData={productions}
                  itemSize={100}
                  itemCount={productions ? productions.length : 0}
                  height={height}
                  width={width}
                  overscanCount={6}
                  outerElementType={outerElementType}
                >
                  {renderRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
        <ContractSelectorModal
          open={
            selectedProgramMemberId.itemType === ItemType.Contract &&
            !!selectedProgramMemberId.ids &&
            selectedProgramMemberId.ids.length > 0
          }
          onClose={handleClose}
          onSelect={handleActionSelect}
        />
        <FunctionSelectorModal
          open={
            selectedProgramMemberId.itemType === ItemType.Function &&
            !!selectedProgramMemberId.ids &&
            selectedProgramMemberId.ids.length > 0
          }
          programMemberId={
            selectedProgramMemberId.itemType === ItemType.Function
              ? selectedProgramMemberId.ids
                ? selectedProgramMemberId.ids[0]
                : null
              : null
          }
          onClose={handleClose}
          onSelect={handleActionSelect}
        />
        <MultiFunctionSelectorModal
          open={
            selectedProgramMemberId.itemType === ItemType.MultiFunction &&
            !!selectedProgramMemberId.ids &&
            selectedProgramMemberId.ids.length > 0
          }
          onClose={handleClose}
          onSelect={handleActionSelect}
        />
        <RecuritmentSelectorModal
          open={
            selectedProgramMemberId.itemType === ItemType.Recruitment &&
            !!selectedProgramMemberId.ids &&
            selectedProgramMemberId.ids.length > 0
          }
          onClose={handleClose}
          onSelect={handleActionSelect}
        />
        <ProgramReferalModal
          open={programReferalOpen}
          displayYear={selectedYear.year}
          onClose={handleProgramReferealClose}
          onSelect={handleProgramSelect}
        />
        <BroadcastReferalModal
          open={broadcastReferalOpen}
          displayYear={selectedYear.year}
          onClose={handleBroadcastReferealClose}
          onSelect={handleProgramSelect}
        />
        <SqlrlDetailsModal
          schema={ProgramSchema}
          fields={[
            "programId",
            "title",
            "channel",
            "genre",
            "episodeNumber",
            "seasonNumber",
            "productionYear",
          ]}
          link={link}
          caption="Produktion"
          title="title"
          editable={false}
          tabs={[
            {
              label: "General",
              renderer: ({ data }) => (
                <Box p={3}>
                  <SqlrlDetailView
                    schema={ProgramSchema}
                    fields={[
                      "programId",
                      "title",
                      "subtitle",
                      "channel",
                      "duration",
                      "genre",
                      "seasonNumber",
                      "episodeNumber",
                      "productionYear",
                      "originalTitle",
                      "seriesTitle",
                      "seasonTitle",
                      "description",
                      "productionNumber",
                    ]}
                    filter={[
                      {
                        fieldName: "programId",
                        value: data.programId,
                      },
                    ]}
                    title="Program"
                  />
                </Box>
              ),
            },
            {
              label: "Udsendelser",
              renderer: ({ data }) => (
                <SqlrlWindowDataTable
                  schema={BroadcastViewSchema}
                  initialFilterVisibility={false}
                  fields={["startTime", "duration", "channel", "ssf"]}
                  filter={[
                    {
                      fieldName: "programId",
                      value: data.programId,
                    },
                    {
                      fieldName: "startTime",
                      value: `${selectedYear.year}-01-01..${
                        parseInt(selectedYear.year as any) + 1
                      }-01-01`,
                    },
                  ]}
                  title="Udsendelse"
                />
              ),
            },
            {
              label: "Personer",
              renderer: ({ data }) => (
                <SqlrlWindowDataTable
                  schema={PersonSchema}
                  initialFilterVisibility={false}
                  fields={["name", "role"]}
                  filter={[
                    {
                      fieldName: "programId",
                      value: data.programId,
                    },
                  ]}
                  disableCounter
                  title="Personer"
                />
              ),
            },
            {
              label: "AVU",
              renderer: ({ data }) =>
                data.programId && (
                  <BroadcastAvuListTab programId={data.programId} />
                ),
            },
          ]}
        />
        <YearPickerDialog
          open={showYearPicker}
          onClose={handleCloseYearPicker}
          value={selectedYear.year}
          onChange={handleYearChange}
          minYear={props.yearRange[0]}
          maxYear={props.yearRange[1]}
        />
      </Paper>
    );
  });
