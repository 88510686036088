import React from "react";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

// Schema
import { IMemberContractSchemaPayload } from "schema";

// Components
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";

interface IRow extends ListChildComponentProps {
  onSelect?: (memberContractId: string) => void;
}
const Row = (props: IRow) => {
  const row = props.data[props.index] as IListItem;
  return (
    <ListItem
      button={(props.onSelect ? true : false) as any}
      style={props.style}
      onClick={() => props.onSelect && props.onSelect(row.memberContractId)}
    >
      <ListItemAvatar>
        <Avatar>
          <Icon>assignment</Icon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={row.title} secondary={row.comment} />
    </ListItem>
  );
};

// Types
type IListItem = Pick<
  IMemberContractSchemaPayload,
  "rowId" | "memberContractId" | "title" | "comment"
>;

//
export interface IContractWindowListProps {
  data: IListItem[] | null;
  onContractClick?: (memberContractId: string) => void;
  style?: React.CSSProperties;
}
export const ContractWindowList: React.FunctionComponent<IContractWindowListProps> = props => {
  const renderRow = React.useCallback(
    (rowProps: ListChildComponentProps) => {
      return <Row {...rowProps} onSelect={props.onContractClick} />;
    },
    [props.onContractClick]
  );
  return (
    <List disablePadding style={props.style}>
      <AutoSizer>
        {({ height, width }) =>
          props.data && props.data.length === 0 ? (
            <div
              style={{
                height,
                width,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography variant="subtitle2" color="textSecondary">
                Husk at uploade din kontrakt oppe i højre hjørne.
              </Typography>
            </div>
          ) : (
            <FixedSizeList
              itemData={props.data}
              itemCount={props.data ? props.data.length : 0}
              itemSize={72}
              height={height}
              width={width}
            >
              {renderRow}
            </FixedSizeList>
          )
        }
      </AutoSizer>
    </List>
  );
};
