import React from "react";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Schema
import { MemberContractSchema, IMemberContractSchemaPayload } from "schema";

// Components
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { ContractWindowList } from "../ContractWindowList";
import { ContractUploadModal } from "../ContractUploadModal";

// Types
type IListItem = Pick<
  IMemberContractSchemaPayload,
  "rowId" | "memberContractId" | "title" | "comment"
>;

//
export interface IContractListProps {}
export const ContractList: React.FunctionComponent<IContractListProps> = props => {
  const authContainer = AuthContainer.useContainer();
  const [open, setOpen] = React.useState<{
    open: boolean;
    memberContractId: null | string;
  }>({ open: false, memberContractId: null });

  const [contracts, setContracts] = React.useState<IListItem[] | null>(null);

  const fetchContracts = () => {
    if (authContainer.context?.memberId) {
      MemberContractSchema.select({
        fields: ["rowId", "memberContractId", "title", "comment"],
        filter: [
          { fieldName: "memberId", value: authContainer.context.memberId }
        ]
      }).then(res => {
        setContracts(res.data);
      });
    }
  };

  React.useEffect(() => {
    fetchContracts();
  }, []);

  const handleOpen = () => {
    setOpen({ open: true, memberContractId: null });
  };

  const handleOpenContract = (memberContractId: string) => {
    setOpen({ open: true, memberContractId: memberContractId });
  };

  const handleCreatedContract = (memberContractId: string) => {
    setOpen({ open: true, memberContractId: memberContractId });
  };

  const handleClose = () => {
    setOpen(state => ({
      open: false,
      memberContractId: state.memberContractId
    }));
    fetchContracts();
  };

  const handleExtied = () => {
    setOpen(state => ({
      open: false,
      memberContractId: null
    }));
    fetchContracts();
  };

  return (
    <Paper
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden"
      }}
    >
      <Toolbar variant="dense" style={{ paddingLeft: 16, paddingRight: 8 }}>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          style={{ fontSize: "16px" }}
        >
          Kontrakter/faktura/honoraropgørelse
        </Typography>
        <div style={{ flex: 1 }}></div>
        <Button startIcon={<Icon>cloud_upload</Icon>} onClick={handleOpen}>
          Upload
        </Button>
      </Toolbar>
      <Divider></Divider>
      <ContractWindowList
        data={contracts}
        onContractClick={handleOpenContract}
        style={{ flexGrow: 1 }}
      />
      <ContractUploadModal
        open={open.open}
        contractId={open.memberContractId}
        onClose={handleClose}
        onCreated={handleCreatedContract}
        onExited={handleExtied}
      />
    </Paper>
  );
};
