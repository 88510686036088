import React from "react";
import { RouteComponentProps } from "@reach/router";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Components
import { SqlrlLogin } from "@discoverr/ui/components/View/SqlrlLogin";

// Assets
const logoPNG = require("assets/dj-logo.png");

//
export interface ILoginProps extends RouteComponentProps {}
export const Login: React.FunctionComponent<ILoginProps> = props => {
  const handleLoginSuccess = () => {
    if (props.location!.state && (props.location!.state as any).backTo) {
      props.navigate!((props.location!.state as any).backTo, {});
    } else {
      props.navigate!("/");
    }
  };
  return (
    <SqlrlLogin
      logo={<img src={logoPNG} style={{ width: "auto", height: 40 }} />}
      usernameLabel="Medlemsnummer"
      passwordLabel="Adgangskode"
      submitLabel="Log på"
      AuthContainer={AuthContainer}
      onLoginSuccess={handleLoginSuccess}
      params={{ type: "MEMBER" }}
    />
  );
};
