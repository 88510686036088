import React from "react";

// Schema
import { MemberContractSchema, IMemberContractSchemaPayload } from "schema";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Constants
import { ItemType } from "../ProgramMemberTable";

// Components
import Dialog from "@material-ui/core/Dialog";

import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import { RecuritmentWindowList } from "../RecuritmentWindowList";

const Item: React.FunctionComponent<{
  recruitmentForm: string;
  onSelect: (recruitmentForm: string) => void;
}> = props => {
  return (
    <ListItem
      button={true}
      onClick={() => props.onSelect(props.recruitmentForm)}
    >
      <ListItemText primary={props.recruitmentForm} />
    </ListItem>
  );
};

// Types
type IListItem = Pick<
  IMemberContractSchemaPayload,
  "rowId" | "memberContractId" | "title" | "comment"
>;

//
export interface IRecuritmentSelectorModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (recuritmentFormId: string, itemType: ItemType) => void;
}
export const RecuritmentSelectorModal: React.FunctionComponent<IRecuritmentSelectorModalProps> = React.memo(
  props => {
    const handleSelect = (recuritmentFormId: string) => {
      props.onClose();
      props.onSelect(recuritmentFormId, ItemType.Recruitment);
    };

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <Toolbar style={{ paddingLeft: 16, paddingRight: 8, height: 80 }}>
          <div>
            <Typography variant="h6">Vælg Ansættelsesform</Typography>
            <Typography variant="caption">* På produktionstidspunktet</Typography>
          </div>
          <div style={{ flex: 1 }}></div>
          <IconButton onClick={props.onClose}>
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
        <Divider></Divider>
        <List disablePadding style={{ height: 400 }}>
          <Item recruitmentForm="Fastansat DR/TV2" onSelect={handleSelect} />
          <Item recruitmentForm="Fastansat Produktionsselskab" onSelect={handleSelect} />
          <Item recruitmentForm="TBA Ansat DR/TV2" onSelect={handleSelect} />
          <Item recruitmentForm="TBA Ansat Produktionsselskab" onSelect={handleSelect} />
          <Item recruitmentForm="Freelance" onSelect={handleSelect} />
          <Item recruitmentForm="Selvstændig" onSelect={handleSelect} />
          <Item recruitmentForm="Tilknyttede tekstningsbureau" onSelect={handleSelect} />
        </List>
      </Dialog>
    );
  }
);
