import React from "react";

// Components
import Dialog from "@material-ui/core/Dialog";
import { DraggablePaper } from "@discoverr/ui/components/Dialog/DraggablePaper";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { BroadcastSchema } from "schema";

//
export interface IBroadcastReferalModalProps {
  open: boolean;
  displayYear: number;
  onClose: () => void;
  onSelect: (item: { title: string | null; programId: string | null }) => void;
}
export const BroadcastReferalModal: React.FunctionComponent<IBroadcastReferalModalProps> = props => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth={true}
      PaperComponent={DraggablePaper}
      PaperProps={{ style: { height: "100%" } }}
    >
      <SqlrlWindowDataTable
        schema={BroadcastSchema}
        fields={[
          "startTime",
          "channel",
          "title",
          "seasonNumber",
          "episodeNumber",
          "subtitle",
          "programId"
        ]}
        filter={[
          {
            fieldName: "startTime",
            value: `${props.displayYear}-01-01..${parseInt(
              props.displayYear + ""
            ) + 1}-01-01`
          },
          {
            fieldName: "programId",
            value: `!`
          }
        ]}
        title="Vælg udsendelse"
        widths={{
          title: 280,
          subtitle: 180
        }}
        onRowClick={props.onSelect}
        showDragHandle
      />
    </Dialog>
  );
};
