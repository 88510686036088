import React from "react";

// Components
import Dialog from "@material-ui/core/Dialog";
import { DraggablePaper } from "@discoverr/ui/components/Dialog/DraggablePaper";
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";
import { BroadcastSchema } from "schema";

//
export interface IProgramReferalModalProps {
  open: boolean;
  displayYear: number;
  onClose: () => void;
  onSelect: (item: { title: string | null; programId: string | null }) => void;
}
export const ProgramReferalModal: React.FunctionComponent<IProgramReferalModalProps> = (
  props
) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      fullWidth={true}
      PaperComponent={DraggablePaper}
      PaperProps={{ style: { height: "100%" } }}
    >
      <SqlrlWindowDataTable
        schema={BroadcastSchema}
        fields={[
          "title",
          "subtitle",
          "seasonNumber",
          "episodeNumber",
          "channel",
          "genre",
          "productionYear",
          "productionNumber",
          "programId",
        ]}
        filter={[
          {
            fieldName: "startTime",
            value: `${props.displayYear}-01-01..${
              parseInt(props.displayYear as any) + 1
            }-01-01`,
          },
        ]}
        groupBy={["programId"]}
        title="Vælg produktion"
        widths={{
          title: 280,
          productionNumber: 180,
          genre: 200,
          subtitle: 180,
          programId: 300,
        }}
        onRowClick={props.onSelect}
        showDragHandle
      />
    </Dialog>
  );
};
