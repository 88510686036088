import React from "react";

// Schema
import { MemberContractSchema, IMemberContractSchemaPayload } from "schema";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";
import { AuthContainer } from "containers/AuthContainer";

// Components
import Dialog from "@material-ui/core/Dialog";

import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import { ContractWindowList } from "../ContractWindowList";

// Constants
import { ItemType } from "../ProgramMemberTable";

// Types
type IListItem = Pick<
  IMemberContractSchemaPayload,
  "rowId" | "memberContractId" | "title" | "comment"
>;

//
export interface IContractSelectorModalProps {
  open: boolean;
  onClose: () => void;
  onSelect: (memberContractId: string, itemType: ItemType) => void;
}
export const ContractSelectorModal: React.FunctionComponent<IContractSelectorModalProps> = React.memo(
  props => {
    const authContainer = AuthContainer.useContainer();
    const [contracts, setContracts] = React.useState<IListItem[] | null>(null);

    const fetchContracts = () => {
      if (authContainer.context?.memberId) {
        MemberContractSchema.select({
          fields: ["rowId", "memberContractId", "title", "comment"],
          filter: [
            { fieldName: "memberId", value: authContainer.context.memberId }
          ]
        }).then(res => {
          setContracts(res.data);
        });
      }
    };

    const handleEnter = () => {
      fetchContracts();
    };
    const handleClose = () => {
      setContracts(null);
      props.onClose();
    };

    const handleSelect = (memberContractId: string) => {
      props.onClose();
      props.onSelect(memberContractId, ItemType.Contract);
    };

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={handleClose}
        onEnter={handleEnter}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <Toolbar style={{ paddingLeft: 16, paddingRight: 8 }}>
          <Typography variant="h6">Vælg kontrakt</Typography>
          <div style={{ flex: 1 }}></div>
          <IconButton onClick={props.onClose}>
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
        <Divider></Divider>
        <ContractWindowList
          data={contracts}
          style={{ height: 400 }}
          onContractClick={handleSelect}
        />
      </Dialog>
    );
  }
);
