import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Components
import Box from "@material-ui/core/Box";

// Styles
const useStyles = makeStyles({
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column"
  }
});

//
export interface IRouterFlexLayoutProps {}
export const RouterFlexLayout = React.forwardRef<any, IRouterFlexLayoutProps>(
  (props, ref) => {
    const classes = useStyles();
    return (
      <div ref={ref} className={classes.root}>
        {props.children}
      </div>
    );
  }
);
