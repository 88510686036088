import axios from "axios";
import LRU from "lru-cache";
const { configure } = require("axios-hooks");

// Constants
const getBaseUrl = (prodHostname: string) => {
  if (window.location.hostname === prodHostname) {
    return "//api.eagleeyes.cloud/";
  }
  return "https://apidev.eagleeyes.cloud/";
};

export const baseUrl = getBaseUrl("medlem.eagleeyes.cloud");

//
export const backend = axios.create({
  baseURL: baseUrl,
  timeout: 20000,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

const cache = new LRU({ max: 10, maxAge: 1000 * 5 });
configure({ axios: backend, cache });
