import React from "react";
import { Router } from "@reach/router";

// Utilities
import { backend } from "backend";

// Components
import { Bootstrap } from "@discoverr/ui/components/Bootstrap";

import { RouterFlexLayout } from "components/Layout";

// Containers
import { AuthContainer } from "containers/AuthContainer";

// Views
import { Login } from "./views/Login";
import { App } from "./views/App";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function isExplore() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    return true;
  } // If another browser, return 0
  else {
    return false;
  }
}

// Constants
const theme = {
  primary: {
    light: "#eeada1",
    main: "#db4f4d",
    dark: "#a23c38",
    contrastText: "#fff",
  },
  secondary: {
    light: "#e9e6e2",
    main: "#cac2b7",
    dark: "#807f82",
    contrastText: "#000",
  },
};

export const Root = () => {
  if (isExplore()) {
    return (
      <Box
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Paper>
          <Box minWidth="500" padding="48px">
            <Typography variant="h5" align="center" gutterBottom>
              Denne browser er ikke supporteret
            </Typography>
            <Typography variant="subtitle2" align="center">
              Brug venligst Google Chrome, Microsoft Edge, Safari eller andre
              moderne browsere.
            </Typography>
          </Box>
        </Paper>
      </Box>
    );
  }
  return (
    <Bootstrap
      initialBackend={backend}
      AuthContainer={AuthContainer}
      theme={theme}
    >
      <AuthContainer.Provider>
        <Router component={RouterFlexLayout}>
          <Login path="/login" />
          <App path="/*" />
        </Router>
      </AuthContainer.Provider>
    </Bootstrap>
  );
};
