import { backend } from "../backend";
import {
  SqlrlSchema,
  ISqlrlSchemaPayload,
} from "@discoverr/ui/utilities/sqlrl";

//
export type ISpaceSettingSchemaPayload = ISqlrlSchemaPayload<
  typeof SpaceSettingSchema
>;
export const SpaceSettingSchema = new SqlrlSchema(
  "_spaceSetting",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    code: SqlrlSchema.createField<string, "text">("code", "Kode", "text", {
      nullable: false,
      maxLength: 50,
    }),
    value: SqlrlSchema.createField<string, "text">("value", "Værdi", "text", {
      nullable: false,
      maxLength: -1,
    }),
  },
  backend
);

export type ISpaceTranslateSchemaPayload = ISqlrlSchemaPayload<
  typeof SpaceTranslateSchema
>;
export const SpaceTranslateSchema = new SqlrlSchema(
  "_spaceTranslate",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    word: SqlrlSchema.createField<string, "text">("word", "Ord", "text", {
      nullable: false,
      maxLength: 100,
    }),
    translated: SqlrlSchema.createField<string | null, "text">(
      "translated",
      "Oversat",
      "text",
      { nullable: true, maxLength: 100 }
    ),
  },
  backend
);

export type IBroadcastSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastSchema
>;
export const BroadcastSchema = new SqlrlSchema(
  "broadcast",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string | null, "text">(
      "status",
      "Status",
      "text",
      { nullable: true, maxLength: 10 }
    ),
    startTime: SqlrlSchema.createField<string | null, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string | null, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    description: SqlrlSchema.createField<string | null, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: true, maxLength: 2000 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionCountry: SqlrlSchema.createField<string | null, "text">(
      "productionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    isReturn: SqlrlSchema.createField<boolean | null, "switch">(
      "isReturn",
      "Genudsendelse",
      "switch",
      { nullable: true, maxLength: null }
    ),
    originalTitle: SqlrlSchema.createField<string | null, "text">(
      "originalTitle",
      "Original titel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seriesTitle: SqlrlSchema.createField<string | null, "text">(
      "seriesTitle",
      "Serietitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seasonTitle: SqlrlSchema.createField<string | null, "text">(
      "seasonTitle",
      "Sæsontitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    announcedStartTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    announcedEndTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedEndTime",
      "Annonceret sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    comment: SqlrlSchema.createField<string | null, "text">(
      "comment",
      "Kommentar",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    approved: SqlrlSchema.createField<boolean, "switch">(
      "approved",
      "Godkendt",
      "switch",
      { nullable: false, maxLength: null }
    ),
    tempVærksID: SqlrlSchema.createField<number | null, "number">(
      "tempVærksID",
      "VærksID",
      "number",
      { nullable: true, maxLength: null }
    ),
    gallupId: SqlrlSchema.createField<string | null, "text">(
      "gallupId",
      "Gallup ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastAvuSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastAvuSchema
>;
export const BroadcastAvuSchema = new SqlrlSchema(
  "broadcast_avu",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    avuId: SqlrlSchema.createField<string, "text">("avuId", null, "text", {
      nullable: false,
      maxLength: null,
    }),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
    copydan: SqlrlSchema.createField<string, "text">("copydan", null, "text", {
      nullable: false,
      maxLength: 20,
    }),
    faust: SqlrlSchema.createField<string, "text">("faust", null, "text", {
      nullable: false,
      maxLength: 20,
    }),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 250,
    }),
    publisher: SqlrlSchema.createField<string, "text">(
      "publisher",
      null,
      "text",
      { nullable: false, maxLength: 20 }
    ),
    stream: SqlrlSchema.createField<number, "number">(
      "stream",
      null,
      "number",
      { nullable: false, maxLength: null }
    ),
    sales: SqlrlSchema.createField<number, "number">("sales", null, "number", {
      nullable: false,
      maxLength: null,
    }),
    announcedStartTime: SqlrlSchema.createField<string, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastCopydanSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastCopydanSchema
>;
export const BroadcastCopydanSchema = new SqlrlSchema(
  "broadcast_copydan",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    copydanId: SqlrlSchema.createField<string, "text">(
      "copydanId",
      null,
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
    id: SqlrlSchema.createField<string | null, "text">("id", null, "text", {
      nullable: true,
      maxLength: 20,
    }),
    reportDate: SqlrlSchema.createField<string | null, "datetime">(
      "reportDate",
      null,
      "datetime",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    seasonId: SqlrlSchema.createField<number | null, "number">(
      "seasonId",
      "Sæson ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonTitle: SqlrlSchema.createField<string | null, "text">(
      "seasonTitle",
      "Sæsontitel",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    genre1: SqlrlSchema.createField<string | null, "text">(
      "genre1",
      null,
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre2: SqlrlSchema.createField<string | null, "text">(
      "genre2",
      null,
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre3: SqlrlSchema.createField<string | null, "text">(
      "genre3",
      null,
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre4: SqlrlSchema.createField<string | null, "text">(
      "genre4",
      null,
      "text",
      { nullable: true, maxLength: 50 }
    ),
    titleSeasonEpisode: SqlrlSchema.createField<string | null, "text">(
      "titleSeasonEpisode",
      null,
      "text",
      { nullable: true, maxLength: 250 }
    ),
    displayYear: SqlrlSchema.createField<number | null, "number">(
      "displayYear",
      "Visningsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    announcedStartTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    announcedEndTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedEndTime",
      "Annonceret sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    firstDisplay: SqlrlSchema.createField<string | null, "datetime">(
      "firstDisplay",
      null,
      "datetime",
      { nullable: true, maxLength: null }
    ),
    alternativeTitle: SqlrlSchema.createField<string | null, "text">(
      "alternativeTitle",
      null,
      "text",
      { nullable: true, maxLength: 250 }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 30 }
    ),
    person: SqlrlSchema.createField<string | null, "text">(
      "person",
      null,
      "text",
      { nullable: true, maxLength: 250 }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastGallupSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastGallupSchema
>;
export const BroadcastGallupSchema = new SqlrlSchema(
  "broadcast_gallup",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    gallupId: SqlrlSchema.createField<string, "text">(
      "gallupId",
      "Gallup ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 100,
    }),
    description: SqlrlSchema.createField<string, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: false, maxLength: 100 }
    ),
    startTime: SqlrlSchema.createField<string, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    episode: SqlrlSchema.createField<string | null, "text">(
      "episode",
      "Episode",
      "text",
      { nullable: true, maxLength: 5 }
    ),
    internalId: SqlrlSchema.createField<string | null, "text">(
      "internalId",
      "Intern ID",
      "text",
      { nullable: true, maxLength: 15 }
    ),
    target: SqlrlSchema.createField<string | null, "text">(
      "target",
      "Mål",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category1: SqlrlSchema.createField<string | null, "text">(
      "category1",
      "Kategori1",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    category2: SqlrlSchema.createField<string | null, "text">(
      "category2",
      "Kategori3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category3: SqlrlSchema.createField<string | null, "text">(
      "category3",
      "Kategori3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category4: SqlrlSchema.createField<string | null, "text">(
      "category4",
      "Kategori4",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    form1: SqlrlSchema.createField<string | null, "text">(
      "form1",
      "Form 1",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    form2: SqlrlSchema.createField<string | null, "text">(
      "form2",
      "Form 2",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    frequency: SqlrlSchema.createField<string | null, "text">(
      "frequency",
      "Hyppighed",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    commonCode1: SqlrlSchema.createField<string | null, "text">(
      "commonCode1",
      "Fælleskode 1",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode2: SqlrlSchema.createField<string | null, "text">(
      "commonCode2",
      "Fælleskode 2",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode3: SqlrlSchema.createField<string | null, "text">(
      "commonCode3",
      "Fælleskode 3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode4: SqlrlSchema.createField<string | null, "text">(
      "commonCode4",
      "Fælleskode 4",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    mergedId: SqlrlSchema.createField<string | null, "text">(
      "mergedId",
      "Samfletnings ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastGallupManualSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastGallupManualSchema
>;
export const BroadcastGallupManualSchema = new SqlrlSchema(
  "broadcast_gallup_manual",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    gallupId: SqlrlSchema.createField<string, "text">(
      "gallupId",
      "Gallup ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 100,
    }),
    description: SqlrlSchema.createField<string, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: false, maxLength: 100 }
    ),
    startTime: SqlrlSchema.createField<string, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    episode: SqlrlSchema.createField<number | null, "number">(
      "episode",
      "Episode",
      "number",
      { nullable: true, maxLength: null }
    ),
    season: SqlrlSchema.createField<number | null, "number">(
      "season",
      "Sæson",
      "number",
      { nullable: true, maxLength: null }
    ),
    internalId: SqlrlSchema.createField<string | null, "text">(
      "internalId",
      "Intern ID",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    target: SqlrlSchema.createField<string | null, "text">(
      "target",
      "Mål",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category1: SqlrlSchema.createField<string | null, "text">(
      "category1",
      "Kategori1",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    category2: SqlrlSchema.createField<string | null, "text">(
      "category2",
      "Kategori3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category3: SqlrlSchema.createField<string | null, "text">(
      "category3",
      "Kategori3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category4: SqlrlSchema.createField<string | null, "text">(
      "category4",
      "Kategori4",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    form1: SqlrlSchema.createField<string | null, "text">(
      "form1",
      "Form 1",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    form2: SqlrlSchema.createField<string | null, "text">(
      "form2",
      "Form 2",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    frequency: SqlrlSchema.createField<string | null, "text">(
      "frequency",
      "Hyppighed",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    commonCode1: SqlrlSchema.createField<string | null, "text">(
      "commonCode1",
      "Fælleskode 1",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode2: SqlrlSchema.createField<string | null, "text">(
      "commonCode2",
      "Fælleskode 2",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode3: SqlrlSchema.createField<string | null, "text">(
      "commonCode3",
      "Fælleskode 3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode4: SqlrlSchema.createField<string | null, "text">(
      "commonCode4",
      "Fælleskode 4",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    mergedId: SqlrlSchema.createField<string | null, "text">(
      "mergedId",
      "Samfletnings ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    gallupEpisode: SqlrlSchema.createField<string | null, "text">(
      "gallupEpisode",
      null,
      "text",
      { nullable: true, maxLength: 5 }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastGallupManual2018SchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastGallupManual2018Schema
>;
export const BroadcastGallupManual2018Schema = new SqlrlSchema(
  "broadcast_gallup_manual_2018",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    gallupId: SqlrlSchema.createField<string, "text">(
      "gallupId",
      "Gallup ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 100,
    }),
    description: SqlrlSchema.createField<string, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: false, maxLength: 100 }
    ),
    startTime: SqlrlSchema.createField<string, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    episode: SqlrlSchema.createField<number | null, "number">(
      "episode",
      "Episode",
      "number",
      { nullable: true, maxLength: null }
    ),
    season: SqlrlSchema.createField<number | null, "number">(
      "season",
      "Sæson",
      "number",
      { nullable: true, maxLength: null }
    ),
    internalId: SqlrlSchema.createField<string | null, "text">(
      "internalId",
      "Intern ID",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    target: SqlrlSchema.createField<string | null, "text">(
      "target",
      "Mål",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category1: SqlrlSchema.createField<string | null, "text">(
      "category1",
      "Kategori1",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    category2: SqlrlSchema.createField<string | null, "text">(
      "category2",
      "Kategori3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category3: SqlrlSchema.createField<string | null, "text">(
      "category3",
      "Kategori3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    category4: SqlrlSchema.createField<string | null, "text">(
      "category4",
      "Kategori4",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    form1: SqlrlSchema.createField<string | null, "text">(
      "form1",
      "Form 1",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    form2: SqlrlSchema.createField<string | null, "text">(
      "form2",
      "Form 2",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    frequency: SqlrlSchema.createField<string | null, "text">(
      "frequency",
      "Hyppighed",
      "text",
      { nullable: true, maxLength: 60 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    commonCode1: SqlrlSchema.createField<string | null, "text">(
      "commonCode1",
      "Fælleskode 1",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode2: SqlrlSchema.createField<string | null, "text">(
      "commonCode2",
      "Fælleskode 2",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode3: SqlrlSchema.createField<string | null, "text">(
      "commonCode3",
      "Fælleskode 3",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    commonCode4: SqlrlSchema.createField<string | null, "text">(
      "commonCode4",
      "Fælleskode 4",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    mergedId: SqlrlSchema.createField<string | null, "text">(
      "mergedId",
      "Samfletnings ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    gallupEpisode: SqlrlSchema.createField<string | null, "text">(
      "gallupEpisode",
      null,
      "text",
      { nullable: true, maxLength: 5 }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastManualSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastManualSchema
>;
export const BroadcastManualSchema = new SqlrlSchema(
  "broadcast_manual",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    broadcastManualId: SqlrlSchema.createField<string, "text">(
      "broadcastManualId",
      null,
      "text",
      { nullable: false, maxLength: null }
    ),
    displayYear: SqlrlSchema.createField<number | null, "number">(
      "displayYear",
      "Visningsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    memberId: SqlrlSchema.createField<number | null, "number">(
      "memberId",
      "Medlemsnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 550,
    }),
    subtitle: SqlrlSchema.createField<string, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: false, maxLength: 1300 }
    ),
    genre: SqlrlSchema.createField<string, "text">("genre", "Genre", "text", {
      nullable: false,
      maxLength: 100,
    }),
    seasonNumber: SqlrlSchema.createField<number, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: false, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: false, maxLength: null }
    ),
    productionYear: SqlrlSchema.createField<number, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: false, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: false, maxLength: 100 }
    ),
    startTime: SqlrlSchema.createField<string, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    duration: SqlrlSchema.createField<number, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: false, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    isReturn: SqlrlSchema.createField<boolean, "switch">(
      "isReturn",
      "Genudsendelse",
      "switch",
      { nullable: false, maxLength: null }
    ),
    name: SqlrlSchema.createField<string | null, "text">(
      "name",
      "Navn",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 30 }
    ),
  },
  backend
);

export type IBroadcastPowernetSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastPowernetSchema
>;
export const BroadcastPowernetSchema = new SqlrlSchema(
  "broadcast_powernet",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    start: SqlrlSchema.createField<string, "datetime">(
      "start",
      "Start",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    end: SqlrlSchema.createField<string, "datetime">(
      "end",
      "Slut",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    powernetId: SqlrlSchema.createField<string | null, "text">(
      "powernetId",
      "PowerNET ID",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 500 }
    ),
    subTitle: SqlrlSchema.createField<string | null, "text">(
      "subTitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 500 }
    ),
    description: SqlrlSchema.createField<string | null, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: true, maxLength: 2000 }
    ),
    eventId: SqlrlSchema.createField<string | null, "text">(
      "eventId",
      "Event ID",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    episode: SqlrlSchema.createField<number | null, "number">(
      "episode",
      "Episode",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodesInSeason: SqlrlSchema.createField<number | null, "number">(
      "episodesInSeason",
      "Episode i sæson",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeId: SqlrlSchema.createField<string | null, "text">(
      "episodeId",
      "Episode ID",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    season: SqlrlSchema.createField<number | null, "number">(
      "season",
      "Sæson",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeTitle: SqlrlSchema.createField<string | null, "text">(
      "episodeTitle",
      "Episode titel",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    seasonId: SqlrlSchema.createField<number | null, "number">(
      "seasonId",
      "Sæson ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    year: SqlrlSchema.createField<number | null, "number">(
      "year",
      "År",
      "number",
      { nullable: true, maxLength: null }
    ),
    country: SqlrlSchema.createField<string | null, "text">(
      "country",
      "Land",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genres: SqlrlSchema.createField<string | null, "text">(
      "genres",
      "Genre",
      "text",
      { nullable: true, maxLength: 50 }
    ),
  },
  backend
);

export type IBroadcastReplaySchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastReplaySchema
>;
export const BroadcastReplaySchema = new SqlrlSchema(
  "broadcast_replay",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    replayId: SqlrlSchema.createField<string, "text">(
      "replayId",
      null,
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 30 }
    ),
    announcedStartTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    platform: SqlrlSchema.createField<string | null, "text">(
      "platform",
      null,
      "text",
      { nullable: true, maxLength: 20 }
    ),
    ssf: SqlrlSchema.createField<number | null, "number">(
      "ssf",
      null,
      "number",
      { nullable: true, maxLength: null }
    ),
    ssfplus: SqlrlSchema.createField<number | null, "number">(
      "ssfplus",
      null,
      "number",
      { nullable: true, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastRitzauSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastRitzauSchema
>;
export const BroadcastRitzauSchema = new SqlrlSchema(
  "broadcast_ritzau",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    start: SqlrlSchema.createField<string, "datetime">(
      "start",
      "Start",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    stop: SqlrlSchema.createField<string, "datetime">(
      "stop",
      "Stop",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    channelName: SqlrlSchema.createField<string | null, "text">(
      "channelName",
      "Kanalnavn",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    broadcastersUniqueId: SqlrlSchema.createField<string | null, "text">(
      "broadcastersUniqueId",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    category: SqlrlSchema.createField<string | null, "text">(
      "category",
      "Kategori",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    country: SqlrlSchema.createField<string | null, "text">(
      "country",
      "Land",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    description: SqlrlSchema.createField<string | null, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: true, maxLength: 2000 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeID: SqlrlSchema.createField<number | null, "number">(
      "episodeID",
      "Episode ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNum: SqlrlSchema.createField<number | null, "number">(
      "episodeNum",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeTitle: SqlrlSchema.createField<string | null, "text">(
      "episodeTitle",
      "Episode titel",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    episodesTotal: SqlrlSchema.createField<number | null, "number">(
      "episodesTotal",
      "Epsiode total",
      "number",
      { nullable: true, maxLength: null }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    originalTitle: SqlrlSchema.createField<string | null, "text">(
      "originalTitle",
      "Original titel",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    programID: SqlrlSchema.createField<number | null, "number">(
      "programID",
      "Program ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonID: SqlrlSchema.createField<number | null, "number">(
      "seasonID",
      "Sæson ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonNum: SqlrlSchema.createField<number | null, "number">(
      "seasonNum",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    seriesID: SqlrlSchema.createField<number | null, "number">(
      "seriesID",
      "Serie ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    seriesName: SqlrlSchema.createField<string | null, "text">(
      "seriesName",
      "Serienavn",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    subtitles: SqlrlSchema.createField<string | null, "text">(
      "subtitles",
      "Undertitler",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    teaser: SqlrlSchema.createField<string | null, "text">(
      "teaser",
      "Teaser",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    year: SqlrlSchema.createField<number | null, "number">(
      "year",
      "År",
      "number",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IIgnoreBroadcastSchemaPayload = ISqlrlSchemaPayload<
  typeof IgnoreBroadcastSchema
>;
export const IgnoreBroadcastSchema = new SqlrlSchema(
  "ignore_broadcast",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    created: SqlrlSchema.createField<string, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 200,
    }),
    productionNumber: SqlrlSchema.createField<string, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: false, maxLength: 50 }
    ),
  },
  backend
);

export type IIgnoreWordSchemaPayload = ISqlrlSchemaPayload<
  typeof IgnoreWordSchema
>;
export const IgnoreWordSchema = new SqlrlSchema(
  "ignore_word",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    word: SqlrlSchema.createField<string, "text">("word", "Ord", "text", {
      nullable: false,
      maxLength: 50,
    }),
    created: SqlrlSchema.createField<string, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: false, maxLength: null }
    ),
  },
  backend
);

export type IMemberSchemaPayload = ISqlrlSchemaPayload<typeof MemberSchema>;
export const MemberSchema = new SqlrlSchema(
  "member",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    memberId: SqlrlSchema.createField<number, "number">(
      "memberId",
      "Medlemsnummer",
      "number",
      { nullable: false, maxLength: null }
    ),
    memberGuid: SqlrlSchema.createField<string | null, "text">(
      "memberGuid",
      "Medlems GUID",
      "text",
      { nullable: true, maxLength: null }
    ),
    password: SqlrlSchema.createField<string | null, "text">(
      "password",
      "Adgangskode",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    name: SqlrlSchema.createField<string | null, "text">(
      "name",
      "Navn",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    address: SqlrlSchema.createField<string | null, "text">(
      "address",
      "Adresse",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    postCode: SqlrlSchema.createField<string | null, "text">(
      "postCode",
      "Postnummer",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    city: SqlrlSchema.createField<string | null, "text">("city", "By", "text", {
      nullable: true,
      maxLength: -1,
    }),
    phoneNumber: SqlrlSchema.createField<string | null, "text">(
      "phoneNumber",
      "Telefonnummer",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    member: SqlrlSchema.createField<boolean | null, "switch">(
      "member",
      "Medlem",
      "switch",
      { nullable: true, maxLength: null }
    ),
    mobile: SqlrlSchema.createField<string | null, "text">(
      "mobile",
      "Mobil",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    email: SqlrlSchema.createField<string | null, "text">(
      "email",
      "Email",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    cpr: SqlrlSchema.createField<string | null, "text">("cpr", "CPR", "text", {
      nullable: true,
      maxLength: 12,
    }),
    alias1: SqlrlSchema.createField<string | null, "text">(
      "alias1",
      "Alias 1",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias2: SqlrlSchema.createField<string | null, "text">(
      "alias2",
      "Alias 2",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias3: SqlrlSchema.createField<string | null, "text">(
      "alias3",
      "Alias 3",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias4: SqlrlSchema.createField<string | null, "text">(
      "alias4",
      "Alias 4",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias5: SqlrlSchema.createField<string | null, "text">(
      "alias5",
      "Alias 5",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias6: SqlrlSchema.createField<string | null, "text">(
      "alias6",
      "Alias 6",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias7: SqlrlSchema.createField<string | null, "text">(
      "alias7",
      "Alias 7",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias8: SqlrlSchema.createField<string | null, "text">(
      "alias8",
      "Alias 8",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias9: SqlrlSchema.createField<string | null, "text">(
      "alias9",
      "Alias 9",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    alias10: SqlrlSchema.createField<string | null, "text">(
      "alias10",
      "Alias 10",
      "text",
      { nullable: true, maxLength: -1 }
    ),
  },
  backend
);

export type IMemberContractSchemaPayload = ISqlrlSchemaPayload<
  typeof MemberContractSchema
>;
export const MemberContractSchema = new SqlrlSchema(
  "member_contract",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    memberContractId: SqlrlSchema.createField<string, "text">(
      "memberContractId",
      "Medlemskontrakt ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    memberId: SqlrlSchema.createField<number, "number">(
      "memberId",
      "Medlemsnummer",
      "number",
      { nullable: false, maxLength: null }
    ),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 50,
    }),
    comment: SqlrlSchema.createField<string, "text">(
      "comment",
      "Kommentar",
      "text",
      { nullable: false, maxLength: 500 }
    ),
    created: SqlrlSchema.createField<string, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    updated: SqlrlSchema.createField<string | null, "datetime">(
      "updated",
      "Opdateret",
      "datetime",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IMemberContractLinkSchemaPayload = ISqlrlSchemaPayload<
  typeof MemberContractLinkSchema
>;
export const MemberContractLinkSchema = new SqlrlSchema(
  "member_contract_link",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    memberContractLinkId: SqlrlSchema.createField<string, "text">(
      "memberContractLinkId",
      "Medlemskontrakt Link",
      "text",
      { nullable: false, maxLength: null }
    ),
    memberContractId: SqlrlSchema.createField<string, "text">(
      "memberContractId",
      "Medlemskontrakt ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    memberId: SqlrlSchema.createField<number, "number">(
      "memberId",
      "Medlemsnummer",
      "number",
      { nullable: false, maxLength: null }
    ),
    link: SqlrlSchema.createField<string, "text">("link", "Link", "text", {
      nullable: false,
      maxLength: 200,
    }),
    filename: SqlrlSchema.createField<string, "text">(
      "filename",
      "Filnavn",
      "text",
      { nullable: false, maxLength: 200 }
    ),
  },
  backend
);

export type IMemberProgramSchemaPayload = ISqlrlSchemaPayload<
  typeof MemberProgramSchema
>;
export const MemberProgramSchema = new SqlrlSchema(
  "member_program",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    programMemberId: SqlrlSchema.createField<string, "text">(
      "programMemberId",
      "Medlemsnummer",
      "text",
      { nullable: false, maxLength: null }
    ),
    displayYear: SqlrlSchema.createField<number, "number">(
      "displayYear",
      "Visningsår",
      "number",
      { nullable: false, maxLength: null }
    ),
    memberId: SqlrlSchema.createField<number, "number">(
      "memberId",
      "Medlemsnummer",
      "number",
      { nullable: false, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 30 }
    ),
    memberContractId: SqlrlSchema.createField<string | null, "text">(
      "memberContractId",
      "Medlemskontrakt ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    notMe: SqlrlSchema.createField<boolean, "switch">(
      "notMe",
      "Ikke mig",
      "switch",
      { nullable: false, maxLength: null }
    ),
    jobFunction: SqlrlSchema.createField<string, "text">(
      "jobFunction",
      "Jobfunktion",
      "text",
      { nullable: false, maxLength: 30 }
    ),
    recruitmentForm: SqlrlSchema.createField<string, "text">(
      "recruitmentForm",
      "Arbejdsform",
      "text",
      { nullable: false, maxLength: 50 }
    ),
    created: SqlrlSchema.createField<string | null, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    updated: SqlrlSchema.createField<string | null, "datetime">(
      "updated",
      "Opdateret",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    copydanReduction: SqlrlSchema.createField<number, "number">(
      "copydanReduction",
      "Reduktion",
      "number",
      { nullable: false, maxLength: null }
    ),
    workdaysReduction: SqlrlSchema.createField<number, "number">(
      "workdaysReduction",
      null,
      "number",
      { nullable: false, maxLength: null }
    ),
    approvedDate: SqlrlSchema.createField<string | null, "datetime">(
      "approvedDate",
      "Godkendelsesdato",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    approvedBy: SqlrlSchema.createField<string | null, "text">(
      "approvedBy",
      "Godkendt af",
      "text",
      { nullable: true, maxLength: null }
    ),
    workdays: SqlrlSchema.createField<number, "number">(
      "workdays",
      "Arbejdsdage",
      "number",
      { nullable: false, maxLength: null }
    ),
    manual: SqlrlSchema.createField<boolean, "switch">(
      "manual",
      "Manuel",
      "switch",
      { nullable: false, maxLength: null }
    ),
    skipCalculation: SqlrlSchema.createField<boolean, "switch">(
      "skipCalculation",
      null,
      "switch",
      { nullable: false, maxLength: null }
    ),
  },
  backend
);

export type IMemberProgramCommentSchemaPayload = ISqlrlSchemaPayload<
  typeof MemberProgramCommentSchema
>;
export const MemberProgramCommentSchema = new SqlrlSchema(
  "member_program_comment",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    memberCommentId: SqlrlSchema.createField<string, "text">(
      "memberCommentId",
      "Kommentar ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    programMemberId: SqlrlSchema.createField<string, "text">(
      "programMemberId",
      "Medlemsnummer",
      "text",
      { nullable: false, maxLength: null }
    ),
    comment: SqlrlSchema.createField<string, "text">(
      "comment",
      "Kommentar",
      "text",
      { nullable: false, maxLength: 500 }
    ),
    userId: SqlrlSchema.createField<string, "text">(
      "userId",
      "Bruger ID",
      "text",
      { nullable: false, maxLength: null }
    ),
  },
  backend
);

export type IMemberProgramYearSchemaPayload = ISqlrlSchemaPayload<
  typeof MemberProgramYearSchema
>;
export const MemberProgramYearSchema = new SqlrlSchema(
  "member_program_year",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    year: SqlrlSchema.createField<number, "number">("year", "År", "number", {
      nullable: false,
      maxLength: null,
    }),
    closed: SqlrlSchema.createField<boolean, "switch">(
      "closed",
      "Lukket",
      "switch",
      { nullable: false, maxLength: null }
    ),
  },
  backend
);

export type IPersonSchemaPayload = ISqlrlSchemaPayload<typeof PersonSchema>;
export const PersonSchema = new SqlrlSchema(
  "person",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 30 }
    ),
    name: SqlrlSchema.createField<string | null, "text">(
      "name",
      "Navn",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    created: SqlrlSchema.createField<string | null, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    fileName: SqlrlSchema.createField<string | null, "text">(
      "fileName",
      "Filnavn",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    tempVærksID: SqlrlSchema.createField<number | null, "number">(
      "tempVærksID",
      "VærksID",
      "number",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IPositionSchemaPayload = ISqlrlSchemaPayload<typeof PositionSchema>;
export const PositionSchema = new SqlrlSchema(
  "position",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    name: SqlrlSchema.createField<string, "text">("name", "Navn", "text", {
      nullable: false,
      maxLength: 50,
    }),
    created: SqlrlSchema.createField<string, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    showOnMemberPortal: SqlrlSchema.createField<boolean, "switch">(
      "showOnMemberPortal",
      "Vis på portal",
      "switch",
      { nullable: false, maxLength: null }
    ),
  },
  backend
);

export type IProgramSchemaPayload = ISqlrlSchemaPayload<typeof ProgramSchema>;
export const ProgramSchema = new SqlrlSchema(
  "program",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    description: SqlrlSchema.createField<string | null, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: true, maxLength: 2000 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    ritzauId: SqlrlSchema.createField<number | null, "number">(
      "ritzauId",
      "Ritzau ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionCountry: SqlrlSchema.createField<string | null, "text">(
      "productionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    originalTitle: SqlrlSchema.createField<string | null, "text">(
      "originalTitle",
      "Original titel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seriesTitle: SqlrlSchema.createField<string | null, "text">(
      "seriesTitle",
      "Serietitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seasonTitle: SqlrlSchema.createField<string | null, "text">(
      "seasonTitle",
      "Sæsontitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    programReduction: SqlrlSchema.createField<number, "number">(
      "programReduction",
      "Reduktion",
      "number",
      { nullable: false, maxLength: null }
    ),
    programReduction2: SqlrlSchema.createField<number, "number">(
      "programReduction2",
      null,
      "number",
      { nullable: false, maxLength: null }
    ),
    skip: SqlrlSchema.createField<boolean, "switch">(
      "skip",
      "Spring over",
      "switch",
      { nullable: false, maxLength: null }
    ),
    tempVærksID: SqlrlSchema.createField<number | null, "number">(
      "tempVærksID",
      "VærksID",
      "number",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IProgramBonanzaSchemaPayload = ISqlrlSchemaPayload<
  typeof ProgramBonanzaSchema
>;
export const ProgramBonanzaSchema = new SqlrlSchema(
  "program_bonanza",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    bonanzaId: SqlrlSchema.createField<string, "text">(
      "bonanzaId",
      null,
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 250,
    }),
    productionNumber: SqlrlSchema.createField<string, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: false, maxLength: 20 }
    ),
    click: SqlrlSchema.createField<number, "number">("click", null, "number", {
      nullable: false,
      maxLength: null,
    }),
    mediaTitle: SqlrlSchema.createField<string, "text">(
      "mediaTitle",
      null,
      "text",
      { nullable: false, maxLength: 250 }
    ),
    role: SqlrlSchema.createField<string, "text">("role", "Funktion", "text", {
      nullable: false,
      maxLength: 100,
    }),
    person: SqlrlSchema.createField<string, "text">("person", null, "text", {
      nullable: false,
      maxLength: 250,
    }),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type ISqlrlLogSchemaPayload = ISqlrlSchemaPayload<typeof SqlrlLogSchema>;
export const SqlrlLogSchema = new SqlrlSchema(
  "sqlrl_log",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    authId: SqlrlSchema.createField<string | null, "text">(
      "authId",
      "BrugerID",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    action: SqlrlSchema.createField<string | null, "text">(
      "action",
      "Ord",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    table: SqlrlSchema.createField<string | null, "text">(
      "table",
      "Tabel",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    filter: SqlrlSchema.createField<string | null, "text">(
      "filter",
      "Filter",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    url: SqlrlSchema.createField<string | null, "text">("url", "URL", "text", {
      nullable: true,
      maxLength: 1000,
    }),
    data: SqlrlSchema.createField<string | null, "text">(
      "data",
      "Data",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    ip: SqlrlSchema.createField<string | null, "text">("ip", "IP", "text", {
      nullable: true,
      maxLength: 50,
    }),
  },
  backend
);

export type IUserSchemaPayload = ISqlrlSchemaPayload<typeof UserSchema>;
export const UserSchema = new SqlrlSchema(
  "user",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    userId: SqlrlSchema.createField<string, "text">(
      "userId",
      "Bruger ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    created: SqlrlSchema.createField<string, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    lastLogin: SqlrlSchema.createField<string, "datetime">(
      "lastLogin",
      "Sidste logind",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    firstName: SqlrlSchema.createField<string, "text">(
      "firstName",
      "Fornavn",
      "text",
      { nullable: false, maxLength: 50 }
    ),
    lastName: SqlrlSchema.createField<string, "text">(
      "lastName",
      "Efternavn",
      "text",
      { nullable: false, maxLength: 50 }
    ),
    email: SqlrlSchema.createField<string, "text">("email", "Email", "text", {
      nullable: false,
      maxLength: 80,
    }),
    password: SqlrlSchema.createField<string, "text">(
      "password",
      "Adgangskode",
      "text",
      { nullable: false, maxLength: 128 }
    ),
    mobile: SqlrlSchema.createField<string, "text">("mobile", "Mobil", "text", {
      nullable: false,
      maxLength: 20,
    }),
    photoUrl: SqlrlSchema.createField<string | null, "text">(
      "photoUrl",
      "Photo link",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 10 }
    ),
    deleted: SqlrlSchema.createField<boolean, "switch">(
      "deleted",
      "Slettet",
      "switch",
      { nullable: false, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastViewSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastViewSchema
>;
export const BroadcastViewSchema = new SqlrlSchema(
  "vw_broadcast",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string | null, "text">(
      "status",
      "Status",
      "text",
      { nullable: true, maxLength: 10 }
    ),
    startTime: SqlrlSchema.createField<string | null, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string | null, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    description: SqlrlSchema.createField<string | null, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: true, maxLength: 2000 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionCountry: SqlrlSchema.createField<string | null, "text">(
      "productionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    isReturn: SqlrlSchema.createField<boolean | null, "switch">(
      "isReturn",
      "Genudsendelse",
      "switch",
      { nullable: true, maxLength: null }
    ),
    originalTitle: SqlrlSchema.createField<string | null, "text">(
      "originalTitle",
      "Original titel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seriesTitle: SqlrlSchema.createField<string | null, "text">(
      "seriesTitle",
      "Serietitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seasonTitle: SqlrlSchema.createField<string | null, "text">(
      "seasonTitle",
      "Sæsontitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    announcedStartTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    announcedEndTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedEndTime",
      "Annonceret sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    comment: SqlrlSchema.createField<string | null, "text">(
      "comment",
      "Kommentar",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    approved: SqlrlSchema.createField<boolean, "switch">(
      "approved",
      "Godkendt",
      "switch",
      { nullable: false, maxLength: null }
    ),
    tempVærksID: SqlrlSchema.createField<number | null, "number">(
      "tempVærksID",
      "VærksID",
      "number",
      { nullable: true, maxLength: null }
    ),
    gallupId: SqlrlSchema.createField<string | null, "text">(
      "gallupId",
      "Gallup ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    ssf: SqlrlSchema.createField<number | null, "number">(
      "ssf",
      null,
      "number",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastApprovalViewSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastApprovalViewSchema
>;
export const BroadcastApprovalViewSchema = new SqlrlSchema(
  "vw_broadcast_approval",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string | null, "text">(
      "status",
      "Status",
      "text",
      { nullable: true, maxLength: 10 }
    ),
    startTime: SqlrlSchema.createField<string | null, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string | null, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    description: SqlrlSchema.createField<string | null, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: true, maxLength: 2000 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionCountry: SqlrlSchema.createField<string | null, "text">(
      "productionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    isReturn: SqlrlSchema.createField<boolean | null, "switch">(
      "isReturn",
      "Genudsendelse",
      "switch",
      { nullable: true, maxLength: null }
    ),
    originalTitle: SqlrlSchema.createField<string | null, "text">(
      "originalTitle",
      "Original titel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seriesTitle: SqlrlSchema.createField<string | null, "text">(
      "seriesTitle",
      "Serietitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seasonTitle: SqlrlSchema.createField<string | null, "text">(
      "seasonTitle",
      "Sæsontitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    announcedStartTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    announcedEndTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedEndTime",
      "Annonceret sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    comment: SqlrlSchema.createField<string | null, "text">(
      "comment",
      "Kommentar",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    approved: SqlrlSchema.createField<boolean, "switch">(
      "approved",
      "Godkendt",
      "switch",
      { nullable: false, maxLength: null }
    ),
    tempVærksID: SqlrlSchema.createField<number | null, "number">(
      "tempVærksID",
      "VærksID",
      "number",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastApprovalDetailViewSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastApprovalDetailViewSchema
>;
export const BroadcastApprovalDetailViewSchema = new SqlrlSchema(
  "vw_broadcast_approval_detail",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string | null, "text">(
      "status",
      "Status",
      "text",
      { nullable: true, maxLength: 10 }
    ),
    startTime: SqlrlSchema.createField<string | null, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string | null, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    description: SqlrlSchema.createField<string | null, "text">(
      "description",
      "Beskrivelse",
      "text",
      { nullable: true, maxLength: 2000 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionCountry: SqlrlSchema.createField<string | null, "text">(
      "productionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    isReturn: SqlrlSchema.createField<boolean | null, "switch">(
      "isReturn",
      "Genudsendelse",
      "switch",
      { nullable: true, maxLength: null }
    ),
    originalTitle: SqlrlSchema.createField<string | null, "text">(
      "originalTitle",
      "Original titel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seriesTitle: SqlrlSchema.createField<string | null, "text">(
      "seriesTitle",
      "Serietitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    seasonTitle: SqlrlSchema.createField<string | null, "text">(
      "seasonTitle",
      "Sæsontitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    announcedStartTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    announcedEndTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedEndTime",
      "Annonceret sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    comment: SqlrlSchema.createField<string | null, "text">(
      "comment",
      "Kommentar",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    approved: SqlrlSchema.createField<boolean, "switch">(
      "approved",
      "Godkendt",
      "switch",
      { nullable: false, maxLength: null }
    ),
    tempVærksID: SqlrlSchema.createField<number | null, "number">(
      "tempVærksID",
      "VærksID",
      "number",
      { nullable: true, maxLength: null }
    ),
    gallupId: SqlrlSchema.createField<string | null, "text">(
      "gallupId",
      "Gallup ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    gallupTitle: SqlrlSchema.createField<string | null, "text">(
      "gallupTitle",
      "Gallup Titel",
      "text",
      { nullable: true, maxLength: 100 }
    ),
  },
  backend
);

export type IBroadcastAvuViewSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastAvuViewSchema
>;
export const BroadcastAvuViewSchema = new SqlrlSchema(
  "vw_broadcast_avu",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    avuId: SqlrlSchema.createField<string, "text">("avuId", null, "text", {
      nullable: false,
      maxLength: null,
    }),
    copydan: SqlrlSchema.createField<string, "text">("copydan", null, "text", {
      nullable: false,
      maxLength: 20,
    }),
    faust: SqlrlSchema.createField<string, "text">("faust", null, "text", {
      nullable: false,
      maxLength: 20,
    }),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 250,
    }),
    publisher: SqlrlSchema.createField<string, "text">(
      "publisher",
      null,
      "text",
      { nullable: false, maxLength: 20 }
    ),
    stream: SqlrlSchema.createField<number, "number">(
      "stream",
      null,
      "number",
      { nullable: false, maxLength: null }
    ),
    sales: SqlrlSchema.createField<number, "number">("sales", null, "number", {
      nullable: false,
      maxLength: null,
    }),
    announcedStartTime: SqlrlSchema.createField<string, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    broadcastTitle: SqlrlSchema.createField<string | null, "text">(
      "broadcastTitle",
      null,
      "text",
      { nullable: true, maxLength: 550 }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
  },
  backend
);

export type IBroadcastCopydanViewSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastCopydanViewSchema
>;
export const BroadcastCopydanViewSchema = new SqlrlSchema(
  "vw_broadcast_copydan",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    copydanId: SqlrlSchema.createField<string, "text">(
      "copydanId",
      null,
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
    id: SqlrlSchema.createField<string | null, "text">("id", null, "text", {
      nullable: true,
      maxLength: 20,
    }),
    reportDate: SqlrlSchema.createField<string | null, "datetime">(
      "reportDate",
      null,
      "datetime",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    seasonId: SqlrlSchema.createField<number | null, "number">(
      "seasonId",
      "Sæson ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonTitle: SqlrlSchema.createField<string | null, "text">(
      "seasonTitle",
      "Sæsontitel",
      "text",
      { nullable: true, maxLength: 250 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    genre1: SqlrlSchema.createField<string | null, "text">(
      "genre1",
      null,
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre2: SqlrlSchema.createField<string | null, "text">(
      "genre2",
      null,
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre3: SqlrlSchema.createField<string | null, "text">(
      "genre3",
      null,
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre4: SqlrlSchema.createField<string | null, "text">(
      "genre4",
      null,
      "text",
      { nullable: true, maxLength: 50 }
    ),
    titleSeasonEpisode: SqlrlSchema.createField<string | null, "text">(
      "titleSeasonEpisode",
      null,
      "text",
      { nullable: true, maxLength: 250 }
    ),
    displayYear: SqlrlSchema.createField<number | null, "number">(
      "displayYear",
      "Visningsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    announcedStartTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    announcedEndTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedEndTime",
      "Annonceret sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    firstDisplay: SqlrlSchema.createField<string | null, "datetime">(
      "firstDisplay",
      null,
      "datetime",
      { nullable: true, maxLength: null }
    ),
    alternativeTitle: SqlrlSchema.createField<string | null, "text">(
      "alternativeTitle",
      null,
      "text",
      { nullable: true, maxLength: 250 }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 30 }
    ),
    person: SqlrlSchema.createField<string | null, "text">(
      "person",
      null,
      "text",
      { nullable: true, maxLength: 250 }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IBroadcastReplayViewSchemaPayload = ISqlrlSchemaPayload<
  typeof BroadcastReplayViewSchema
>;
export const BroadcastReplayViewSchema = new SqlrlSchema(
  "vw_broadcast_replay",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    replayId: SqlrlSchema.createField<string, "text">(
      "replayId",
      null,
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 30 }
    ),
    announcedStartTime: SqlrlSchema.createField<string | null, "datetime">(
      "announcedStartTime",
      "Annonceret starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    platform: SqlrlSchema.createField<string | null, "text">(
      "platform",
      null,
      "text",
      { nullable: true, maxLength: 20 }
    ),
    ssf: SqlrlSchema.createField<number | null, "number">(
      "ssf",
      null,
      "number",
      { nullable: true, maxLength: null }
    ),
    ssfplus: SqlrlSchema.createField<number | null, "number">(
      "ssfplus",
      null,
      "number",
      { nullable: true, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IExportBoadcastPersonViewSchemaPayload = ISqlrlSchemaPayload<
  typeof ExportBoadcastPersonViewSchema
>;
export const ExportBoadcastPersonViewSchema = new SqlrlSchema(
  "vw_export_boadcast_person",
  {
    rowId: SqlrlSchema.createField<number | null, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    startTime: SqlrlSchema.createField<string | null, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string | null, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 4000 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 4000 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionCountry: SqlrlSchema.createField<string | null, "text">(
      "productionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    name: SqlrlSchema.createField<string | null, "text">(
      "name",
      "Navn",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 30 }
    ),
  },
  backend
);

export type IExportTestViewSchemaPayload = ISqlrlSchemaPayload<
  typeof ExportTestViewSchema
>;
export const ExportTestViewSchema = new SqlrlSchema(
  "vw_export_test",
  {
    broadcastId: SqlrlSchema.createField<string | null, "text">(
      "broadcastId",
      "Broadcast ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    startTime: SqlrlSchema.createField<string | null, "datetime">(
      "startTime",
      "Starttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    endTime: SqlrlSchema.createField<string | null, "datetime">(
      "endTime",
      "Sluttid",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    duration: SqlrlSchema.createField<number | null, "number">(
      "duration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionCountry: SqlrlSchema.createField<string | null, "text">(
      "productionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    name: SqlrlSchema.createField<string | null, "text">(
      "name",
      "Navn",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 30 }
    ),
  },
  backend
);

export type IMemberContractLinkViewSchemaPayload = ISqlrlSchemaPayload<
  typeof MemberContractLinkViewSchema
>;
export const MemberContractLinkViewSchema = new SqlrlSchema(
  "vw_member_contract_link",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    memberContractLinkId: SqlrlSchema.createField<string, "text">(
      "memberContractLinkId",
      "Medlemskontrakt Link",
      "text",
      { nullable: false, maxLength: null }
    ),
    memberContractId: SqlrlSchema.createField<string, "text">(
      "memberContractId",
      "Medlemskontrakt ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    memberId: SqlrlSchema.createField<number, "number">(
      "memberId",
      "Medlemsnummer",
      "number",
      { nullable: false, maxLength: null }
    ),
    link: SqlrlSchema.createField<string, "text">("link", "Link", "text", {
      nullable: false,
      maxLength: 200,
    }),
    filename: SqlrlSchema.createField<string, "text">(
      "filename",
      "Filnavn",
      "text",
      { nullable: false, maxLength: 200 }
    ),
    memberContractTitle: SqlrlSchema.createField<string | null, "text">(
      "memberContractTitle",
      "Medlemskontrakt Titel",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    comment: SqlrlSchema.createField<string | null, "text">(
      "comment",
      "Kommentar",
      "text",
      { nullable: true, maxLength: 500 }
    ),
  },
  backend
);

export type IMemberProgramViewSchemaPayload = ISqlrlSchemaPayload<
  typeof MemberProgramViewSchema
>;
export const MemberProgramViewSchema = new SqlrlSchema(
  "vw_member_program",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    programMemberId: SqlrlSchema.createField<string, "text">(
      "programMemberId",
      "Medlemsnummer",
      "text",
      { nullable: false, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    memberId: SqlrlSchema.createField<number, "number">(
      "memberId",
      "Medlemsnummer",
      "number",
      { nullable: false, maxLength: null }
    ),
    memberContractId: SqlrlSchema.createField<string | null, "text">(
      "memberContractId",
      "Medlemskontrakt ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    created: SqlrlSchema.createField<string | null, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    updated: SqlrlSchema.createField<string | null, "datetime">(
      "updated",
      "Opdateret",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    displayYear: SqlrlSchema.createField<number, "number">(
      "displayYear",
      "Visningsår",
      "number",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 30 }
    ),
    notMe: SqlrlSchema.createField<boolean, "switch">(
      "notMe",
      "Ikke mig",
      "switch",
      { nullable: false, maxLength: null }
    ),
    jobFunction: SqlrlSchema.createField<string, "text">(
      "jobFunction",
      "Jobfunktion",
      "text",
      { nullable: false, maxLength: 30 }
    ),
    recruitmentForm: SqlrlSchema.createField<string, "text">(
      "recruitmentForm",
      "Arbejdsform",
      "text",
      { nullable: false, maxLength: 50 }
    ),
    programChannel: SqlrlSchema.createField<string | null, "text">(
      "programChannel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    programTitle: SqlrlSchema.createField<string | null, "text">(
      "programTitle",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    programSubtitle: SqlrlSchema.createField<string | null, "text">(
      "programSubtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    programSeasonNumber: SqlrlSchema.createField<number | null, "number">(
      "programSeasonNumber",
      "Sæson nummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    programEpisodeNumber: SqlrlSchema.createField<number | null, "number">(
      "programEpisodeNumber",
      "Episode",
      "number",
      { nullable: true, maxLength: null }
    ),
    programProductionNumber: SqlrlSchema.createField<string | null, "text">(
      "programProductionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    programProductionYear: SqlrlSchema.createField<number | null, "number">(
      "programProductionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    programProductionCountry: SqlrlSchema.createField<string | null, "text">(
      "programProductionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    contractTitle: SqlrlSchema.createField<string | null, "text">(
      "contractTitle",
      "Kontrakt titel",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    memberName: SqlrlSchema.createField<string | null, "text">(
      "memberName",
      "Medlemsnavn",
      "text",
      { nullable: true, maxLength: -1 }
    ),
    approvedDate: SqlrlSchema.createField<string | null, "datetime">(
      "approvedDate",
      "Godkendelsesdato",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    copydanReduction: SqlrlSchema.createField<number, "number">(
      "copydanReduction",
      "Reduktion",
      "number",
      { nullable: false, maxLength: null }
    ),
    workdays: SqlrlSchema.createField<number, "number">(
      "workdays",
      "Arbejdsdage",
      "number",
      { nullable: false, maxLength: null }
    ),
    manual: SqlrlSchema.createField<boolean, "switch">(
      "manual",
      "Manuel",
      "switch",
      { nullable: false, maxLength: null }
    ),
    approvedBy: SqlrlSchema.createField<string | null, "text">(
      "approvedBy",
      "Godkendt af",
      "text",
      { nullable: true, maxLength: null }
    ),
    userEmail: SqlrlSchema.createField<string | null, "text">(
      "userEmail",
      "Email",
      "text",
      { nullable: true, maxLength: 80 }
    ),
    commentCount: SqlrlSchema.createField<number | null, "number">(
      "commentCount",
      "Kommentar",
      "number",
      { nullable: true, maxLength: null }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    skipCalculation: SqlrlSchema.createField<boolean, "switch">(
      "skipCalculation",
      null,
      "switch",
      { nullable: false, maxLength: null }
    ),
    workdaysReduction: SqlrlSchema.createField<number, "number">(
      "workdaysReduction",
      null,
      "number",
      { nullable: false, maxLength: null }
    ),
  },
  backend
);

export type IMemberProgramCommentViewSchemaPayload = ISqlrlSchemaPayload<
  typeof MemberProgramCommentViewSchema
>;
export const MemberProgramCommentViewSchema = new SqlrlSchema(
  "vw_member_program_comment",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    memberCommentId: SqlrlSchema.createField<string, "text">(
      "memberCommentId",
      "Kommentar ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    programMemberId: SqlrlSchema.createField<string, "text">(
      "programMemberId",
      "Medlemsnummer",
      "text",
      { nullable: false, maxLength: null }
    ),
    comment: SqlrlSchema.createField<string, "text">(
      "comment",
      "Kommentar",
      "text",
      { nullable: false, maxLength: 500 }
    ),
    userId: SqlrlSchema.createField<string, "text">(
      "userId",
      "Bruger ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    userEmail: SqlrlSchema.createField<string | null, "text">(
      "userEmail",
      "Email",
      "text",
      { nullable: true, maxLength: 80 }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    programTitle: SqlrlSchema.createField<string | null, "text">(
      "programTitle",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    programSubtitle: SqlrlSchema.createField<string | null, "text">(
      "programSubtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    programSeasonNumber: SqlrlSchema.createField<number | null, "number">(
      "programSeasonNumber",
      "Sæson nummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    programEpisodeNumber: SqlrlSchema.createField<number | null, "number">(
      "programEpisodeNumber",
      "Episode",
      "number",
      { nullable: true, maxLength: null }
    ),
    programProductionNumber: SqlrlSchema.createField<string | null, "text">(
      "programProductionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    programProductionYear: SqlrlSchema.createField<number | null, "number">(
      "programProductionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IPersonViewSchemaPayload = ISqlrlSchemaPayload<
  typeof PersonViewSchema
>;
export const PersonViewSchema = new SqlrlSchema(
  "vw_person",
  {
    rowId: SqlrlSchema.createField<number | null, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    name: SqlrlSchema.createField<string | null, "text">(
      "name",
      "Navn",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 30 }
    ),
  },
  backend
);

export type IPersonProgramViewSchemaPayload = ISqlrlSchemaPayload<
  typeof PersonProgramViewSchema
>;
export const PersonProgramViewSchema = new SqlrlSchema(
  "vw_person_program",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 30 }
    ),
    name: SqlrlSchema.createField<string | null, "text">(
      "name",
      "Navn",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    created: SqlrlSchema.createField<string | null, "datetime">(
      "created",
      "Oprettet",
      "datetime",
      { nullable: true, maxLength: null }
    ),
    programChannel: SqlrlSchema.createField<string | null, "text">(
      "programChannel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    programTitle: SqlrlSchema.createField<string | null, "text">(
      "programTitle",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    programSubtitle: SqlrlSchema.createField<string | null, "text">(
      "programSubtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    programDescription: SqlrlSchema.createField<string | null, "text">(
      "programDescription",
      "Beskrivelse",
      "text",
      { nullable: true, maxLength: 2000 }
    ),
    programDuration: SqlrlSchema.createField<number | null, "number">(
      "programDuration",
      "Varighed",
      "number",
      { nullable: true, maxLength: null }
    ),
    programSeasonNumber: SqlrlSchema.createField<number | null, "number">(
      "programSeasonNumber",
      "Sæson nummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    programEpisodeNumber: SqlrlSchema.createField<number | null, "number">(
      "programEpisodeNumber",
      "Episode",
      "number",
      { nullable: true, maxLength: null }
    ),
    programProductionNumber: SqlrlSchema.createField<string | null, "text">(
      "programProductionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    programProductionYear: SqlrlSchema.createField<number | null, "number">(
      "programProductionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    programCountry: SqlrlSchema.createField<string | null, "text">(
      "programCountry",
      "Land",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    programGenre: SqlrlSchema.createField<string | null, "text">(
      "programGenre",
      "Genre",
      "text",
      { nullable: true, maxLength: 100 }
    ),
    programOriginalTitle: SqlrlSchema.createField<string | null, "text">(
      "programOriginalTitle",
      "Original Titel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    programSeriesTitle: SqlrlSchema.createField<string | null, "text">(
      "programSeriesTitle",
      "Serietitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
    programSeasonTitle: SqlrlSchema.createField<string | null, "text">(
      "programSeasonTitle",
      "Sæsontitel",
      "text",
      { nullable: true, maxLength: 200 }
    ),
  },
  backend
);

export type IProgramBonanzaViewSchemaPayload = ISqlrlSchemaPayload<
  typeof ProgramBonanzaViewSchema
>;
export const ProgramBonanzaViewSchema = new SqlrlSchema(
  "vw_program_bonanza",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    timestamp: SqlrlSchema.createField<string, "datetime">(
      "timestamp",
      "Timestamp",
      "datetime",
      { nullable: false, maxLength: null }
    ),
    bonanzaId: SqlrlSchema.createField<string, "text">(
      "bonanzaId",
      null,
      "text",
      { nullable: false, maxLength: null }
    ),
    status: SqlrlSchema.createField<string, "text">(
      "status",
      "Status",
      "text",
      { nullable: false, maxLength: 10 }
    ),
    title: SqlrlSchema.createField<string, "text">("title", "Titel", "text", {
      nullable: false,
      maxLength: 250,
    }),
    productionNumber: SqlrlSchema.createField<string, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: false, maxLength: 20 }
    ),
    click: SqlrlSchema.createField<number, "number">("click", null, "number", {
      nullable: false,
      maxLength: null,
    }),
    mediaTitle: SqlrlSchema.createField<string, "text">(
      "mediaTitle",
      null,
      "text",
      { nullable: false, maxLength: 250 }
    ),
    role: SqlrlSchema.createField<string, "text">("role", "Funktion", "text", {
      nullable: false,
      maxLength: 100,
    }),
    person: SqlrlSchema.createField<string, "text">("person", null, "text", {
      nullable: false,
      maxLength: 250,
    }),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    programTitle: SqlrlSchema.createField<string | null, "text">(
      "programTitle",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionCountry: SqlrlSchema.createField<string | null, "text">(
      "productionCountry",
      "Produktionsland",
      "text",
      { nullable: true, maxLength: 50 }
    ),
  },
  backend
);

export type IProgramByYearViewSchemaPayload = ISqlrlSchemaPayload<
  typeof ProgramByYearViewSchema
>;
export const ProgramByYearViewSchema = new SqlrlSchema(
  "vw_program_by_year",
  {
    displayYear: SqlrlSchema.createField<string | null, "text">(
      "displayYear",
      "Visningsår",
      "text",
      { nullable: true, maxLength: 4 }
    ),
    rowId: SqlrlSchema.createField<number | null, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: true, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string | null, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: true, maxLength: null }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    subtitle: SqlrlSchema.createField<string | null, "text">(
      "subtitle",
      "Undertitel",
      "text",
      { nullable: true, maxLength: 1300 }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    genre: SqlrlSchema.createField<string | null, "text">(
      "genre",
      "Genre",
      "text",
      { nullable: true, maxLength: 50 }
    ),
    seasonNumber: SqlrlSchema.createField<number | null, "number">(
      "seasonNumber",
      "Sæsonnummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    episodeNumber: SqlrlSchema.createField<number | null, "number">(
      "episodeNumber",
      "Episodenummer",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionYear: SqlrlSchema.createField<number | null, "number">(
      "productionYear",
      "Produktionsår",
      "number",
      { nullable: true, maxLength: null }
    ),
    productionNumber: SqlrlSchema.createField<string | null, "text">(
      "productionNumber",
      "Produktionsnummer",
      "text",
      { nullable: true, maxLength: 100 }
    ),
  },
  backend
);

export type IProgramChannelViewSchemaPayload = ISqlrlSchemaPayload<
  typeof ProgramChannelViewSchema
>;
export const ProgramChannelViewSchema = new SqlrlSchema(
  "vw_program_channel",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    channel: SqlrlSchema.createField<string, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: false, maxLength: 18 }
    ),
  },
  backend
);

export type IProgramPersonMissingViewSchemaPayload = ISqlrlSchemaPayload<
  typeof ProgramPersonMissingViewSchema
>;
export const ProgramPersonMissingViewSchema = new SqlrlSchema(
  "vw_program_person_missing",
  {
    rowId: SqlrlSchema.createField<number, "number">(
      "rowId",
      "Row ID",
      "number",
      { nullable: false, maxLength: null }
    ),
    programId: SqlrlSchema.createField<string, "text">(
      "programId",
      "Program ID",
      "text",
      { nullable: false, maxLength: null }
    ),
    title: SqlrlSchema.createField<string | null, "text">(
      "title",
      "Titel",
      "text",
      { nullable: true, maxLength: 550 }
    ),
    channel: SqlrlSchema.createField<string | null, "text">(
      "channel",
      "Kanal",
      "text",
      { nullable: true, maxLength: 20 }
    ),
    persons: SqlrlSchema.createField<number | null, "number">(
      "persons",
      "Personer",
      "number",
      { nullable: true, maxLength: null }
    ),
  },
  backend
);

export type IRoleViewSchemaPayload = ISqlrlSchemaPayload<typeof RoleViewSchema>;
export const RoleViewSchema = new SqlrlSchema(
  "vw_role",
  {
    role: SqlrlSchema.createField<string | null, "text">(
      "role",
      "Funktion",
      "text",
      { nullable: true, maxLength: 30 }
    ),
    rowId: SqlrlSchema.createField<string, "text">("rowId", "Row ID", "text", {
      nullable: false,
      maxLength: 1,
    }),
  },
  backend
);
