import React from "react";

// Utilities
import { ISqlrlSchemaPayload } from "@discoverr/ui/utilities/sqlrl";

// Components
import { SqlrlWindowDataTable } from "@discoverr/ui/components/Table/SqlrlWindowDataTable";

// Models
import { BroadcastAvuViewSchema } from "schema";

//
export interface IBroadcastAvuListTabProps {
  programId: ISqlrlSchemaPayload<typeof BroadcastAvuViewSchema>["programId"];
}
export const BroadcastAvuListTab: React.FunctionComponent<IBroadcastAvuListTabProps> = ({
  programId,
}) => {
  return (
    <React.Fragment>
      <SqlrlWindowDataTable
        schema={BroadcastAvuViewSchema}
        fields={[
          "publisher",
          "announcedStartTime",
          "title",
          "stream",
          "sales",
          "copydan",
        ]}
        widths={{ title: 300, copydan: 140, announcedStartTime: 200 }}
        filter={[
          {
            fieldName: "programId",
            value: programId,
          },
          {
            fieldName: "status",
            value: "OK",
          },
        ]}
        title="AVU"
      />
    </React.Fragment>
  );
};
