import React from "react";

// Schema
import { SpaceSettingSchema } from "schema";

// Components
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

//
export interface ITermsOfAgreementModalProps {}
export const TermsOfAgreementModal: React.FunctionComponent<ITermsOfAgreementModalProps> = props => {
  const [open, setOpen] = React.useState(false);
  const [agreement, setAgreement] = React.useState<string | null>(
    "Henter oplysninger..."
  );

  React.useEffect(() => {
    if (!sessionStorage.getItem("TermsOfAgreementModal")) {
      setOpen(true);
      SpaceSettingSchema.select({
        fields: ["value"],
        filter: [{ fieldName: "code", value: "SITE_MEMBER_GDPR" }]
      }).then(res => {
        if (res.data && res.data[0]) {
          setAgreement(res.data[0].value);
        }
      });
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem("TermsOfAgreementModal", "true");
  };
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
    >
      <DialogTitle id="customized-dialog-title">Betingelser</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{agreement}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Accepter
        </Button>
      </DialogActions>
    </Dialog>
  );
};
