import React from "react";

// Backend
import { backend } from "backend";

// Schema
import { MemberContractSchema, IMemberContractSchemaPayload } from "schema";

// Containers
import { DialogContainer } from "@discoverr/ui/containers/DialogContainer";
import { AuthContainer } from "containers/AuthContainer";

// Components
import Dialog from "@material-ui/core/Dialog";

import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

import { ContractWindowList } from "../ContractWindowList";

// Constants
import { ItemType } from "../ProgramMemberTable";

// Types
type IListItem = {
  role: string;
};

// Components
const Item: React.FunctionComponent<{
  role: string;
  onSelect: (role: string) => void;
}> = props => {
  return (
    <ListItem button={true} onClick={() => props.onSelect(props.role)}>
      <ListItemText primary={props.role} />
    </ListItem>
  );
};

//
export interface IFunctionSelectorModalProps {
  open: boolean;
  programMemberId?: string | null | false;
  onClose: () => void;
  onSelect: (memberContractId: string, itemType: ItemType) => void;
}
export const FunctionSelectorModal: React.FunctionComponent<IFunctionSelectorModalProps> = React.memo(
  props => {
    const authContainer = AuthContainer.useContainer();
    const [functions, setFunctions] = React.useState<IListItem[] | null>(null);

    const fetchContracts = () => {
      if (authContainer.context?.memberId) {
        backend
          .get("/sqlrl/custom/sp_member_person_function", {
            params: {
              programMemberId: props.programMemberId
            }
          })
          .then(res => {
            setFunctions(res.data);
          });
      }
    };

    const handleEnter = () => {
      fetchContracts();
    };

    const handleClose = () => {
      setFunctions(null);
      props.onClose();
    };

    const handleSelect = (role: string) => {
      props.onClose();
      props.onSelect(role, ItemType.Function);
    };

    return (
      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={handleClose}
        onEnter={handleEnter}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <Toolbar style={{ paddingLeft: 16, paddingRight: 8 }}>
          <Typography variant="h6">Vælg Jobfunktion</Typography>
          <div style={{ flex: 1 }}></div>
          <IconButton onClick={props.onClose}>
            <Icon>close</Icon>
          </IconButton>
        </Toolbar>
        <Divider></Divider>
        <List disablePadding style={{ height: 400 }}>
          {functions?.map(item => (
            <Item role={item.role} onSelect={handleSelect} />
          ))}
        </List>
      </Dialog>
    );
  }
);
