import React from "react";
import { RouteComponentProps } from "@reach/router";
import {} from "./";

// Components
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { Container } from "@discoverr/ui/components/Layout/Container";

import { ProgramMemberTable } from "./components/ProgramMemberTable";
import { MemberInfo } from "./components/MemberInfo";
import { ContractList } from "./components/ContractList";
import { SiteInfo } from "./components/SiteInfo";

// Schema
import {
  MemberProgramYearSchema,
  IMemberProgramYearSchemaPayload,
} from "schema";

//
export interface IApprovalProps extends RouteComponentProps {}
export const Approval: React.FunctionComponent<IApprovalProps> = (props) => {
  const [years, setYears] = React.useState<
    Pick<IMemberProgramYearSchemaPayload, "year" | "closed">[] | null
  >(null);

  React.useEffect(() => {
    MemberProgramYearSchema.select({
      fields: ["year", "closed", "rowId", "timestamp"],
    }).then((res) => {
      if (res.data) {
        setYears(res.data);
      }
    });
  }, []);

  const yearRange: [number, number] | null = years
    ? [years[0].year, years[years.length - 1].year]
    : null;

  const getYearClosedStatus = (year: number) => {
    if (years) {
      return years.filter((item) => item.year == year)[0].closed;
    }
    return false;
  };

  return (
    <Container maxWidth="xl" fullHeight style={{ height: "100%" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Grid
            container
            spacing={3}
            style={{ marginBottom: 8, minHeight: 200 }}
          >
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                height: 190,
              }}
            >
              <SiteInfo></SiteInfo>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                height: 190,
              }}
            >
              <MemberInfo></MemberInfo>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                height: 190,
              }}
            >
              <ContractList></ContractList>
            </Grid>
          </Grid>
          {yearRange ? (
            <ProgramMemberTable
              yearRange={yearRange}
              getYearClosedStatus={getYearClosedStatus}
            ></ProgramMemberTable>
          ) : (
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
              }}
            ></Paper>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
